
import { useContext } from "react";
import AuthContext from "../../context/AuthProvider";

const useAuth = () => {
    // console.log("inside Auth  AuthContext")
    return useContext(AuthContext);
    // console.log("inside Auth after AuthContext")
}

export default useAuth;