import DateTimeDisplay from '../DateTimeDisplay';
import {useCountdown} from '../../hooks/useCountdown';

const fontStyle = {
    color: "yellow",
    fontFamily: "Sans-Serif",
    fontSize: 7,
};

const ShowCounter = ({days, hours, minutes, seconds}) => {
    return (

        <a
            className="countdown-link red"
        >
            {days !== 0 ?
                <>
                    <DateTimeDisplay value={days} isDanger={days <= 3}/>
                    <p style={fontStyle}>Days</p>
                </>
                : ''}
            <DateTimeDisplay value={hours} isDanger={false}/>
            <p style={fontStyle}>Hrs</p>
            <DateTimeDisplay value={minutes} isDanger={false}/>
            <p style={fontStyle}>Min</p>
            <DateTimeDisplay value={seconds} isDanger={false}/>
            <p style={fontStyle}>Sec</p>
        </a>

    );
};

const CountdownTimer = ({targetDate}) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return (
            'Draw Closed'
        );
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;