import {withTranslation} from "react-i18next";
import {HashLink as Link} from 'react-router-hash-link'
import {animateScroll as scroll} from 'react-scroll'
import {useHistory, useLocation} from 'react-router-dom'
import {useEffect, useState} from "react";
import {axiosPrivate} from "../../api/axios";
import ReactLoading from "react-loading";

// Material UI Elements
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button, {ButtonProps} from '@mui/material/Button';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import NumberFormat from "react-number-format";
import dateFormat from "dateformat";
import CountdownTimer from "../CountdownTimer";
import {amber, blue, grey} from "@mui/material/colors";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const GoldButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: theme.palette.getContrastText(amber[600]),
    backgroundColor: amber[600],
    '&:hover': {
        backgroundColor: amber[800],
    },
}));

const BlackButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: theme.palette.getContrastText(grey[700]),
    backgroundColor: grey[700],
    '&:hover': {
        backgroundColor: grey[900],
    },
}));

const BlueButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: theme.palette.getContrastText(blue[700]),
    backgroundColor: blue[700],
    '&:hover': {
        backgroundColor: blue[900],
    },
}));

const IPayLotteryGrid = (props: any) => {
        const {
            t,
            setQuickOpen,
            setScheduleOpen,
            setSelectedLottery,
            setLoginOpen,
            setSelectedTab
        } = props
        const navigate = useHistory()
        const location = useLocation()
        const [lotteries, setLotteries] = useState()
        const [message, setMessage] = useState('')
        const [specialLottery, setSpecialLottery] = useState([35, 45])
        const [token, setToken] = useState('')

        useEffect(() => {
            let isMounted = true
            const controller = new AbortController()

            const getLotteries = async () => {
                try {
                    let atkn = localStorage.getItem('atkn')
                    setToken(atkn)
                    const response = await axiosPrivate.post('lottery/list', {
                        signal: controller.signal,
                    })
                    isMounted && setLotteries(response.data?.lotttery)
                    if (response.data?.lotttery.length == 0)
                        setMessage('No lotteries to display')
                } catch (err) {
                    // console.error(err)
                }
            }

            getLotteries()

            return () => {
                isMounted = false
                controller.abort()
            }
        }, [])

        const handleQuickBuyModelOpen = (lottery: any, tab_id: number) => {
            if (token) {
                setQuickOpen(true)
                setSelectedLottery(lottery)
                setSelectedTab(tab_id)
            } else {
                setLoginOpen(true)
            }
        }

        const handleScheduleModelOpen = (lottery: any) => {
            if (token) {
                setScheduleOpen(true)
                setSelectedLottery(lottery)
            } else {
                setLoginOpen(true)
            }
        }

        const from = location.state?.from?.pathname || '/'
        var settings = {
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 7,
            autoplay: true,
            arrows: false,
            autoplaySpeed: 2000,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },

            ],
        };

        const handleScroll = () => {
            navigate.push(from)
            // scroll.scrollTo(1450);
            const isMobile = window.innerWidth <= 767;
            const isTablet = window.innerWidth > 767 && window.innerWidth <= 1024;

            navigate.push(from);

            if (isMobile) {
                scroll.scrollTo(3500);
            } else if (isTablet) {
                scroll.scrollTo(1900);
            } else {
                scroll.scrollTo(1250);
            }
        }


        return (
            <div className="container">
                <div className="row top-wrapper p-5">
                    <div
                        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 wrapper three"
                        data-aos="fade-up"
                    >
                        <h2 className='ipay-title'>{t('BUY YOUR LUCKY NLB LOTTERIES HERE !!')}</h2>
                    </div>
                </div>
                <Grid container spacing={2} className='ipay-lottery-grid'>
                    {
                        lotteries ? lotteries.map((lottery, index) => (
                                <Grid item xs={12} md={6}>
                                    {specialLottery.includes(lottery.lottery_id) ?
                                        <div className="ribbon-wrapper">
                                            <div className="ribbon">
                                                SPECIAL
                                            </div>
                                        </div>
                                        : ''
                                    }
                                    <Item className='lottery-card'>
                                        <div className="inner-wrapper">
                                            <div className="logo-box row">
                                                <div className="col-xs-4">
                                                    <Link to={`/ln?tk=${lottery.code.toLowerCase()}`}>
                                                        <img src={lottery.image_file_path} className="img-responsive"
                                                             alt="Image"/>
                                                    </Link>
                                                </div>

                                                <div className="col-xs-8">
                                                    <div className="next-super-prize-container">
                                                        <small className="next-super-prize-title">
                                                            Next Super Prize
                                                        </small>
                                                        <span className="next-super-prize">{t('Rs.')}
                                                            <NumberFormat value={
                                                                lottery.next_super_price
                                                                    ? Number(lottery.next_super_price).toFixed(2)
                                                                    : Number(lottery.winning_price).toFixed(2)
                                                            }
                                                                          displayType={'text'}
                                                                          thousandSeparator={true}
                                                            />
                                                    </span>
                                                    </div>
                                                    <h6 className='lottery-name'>{lottery.name}</h6>
                                                </div>
                                            </div>
                                            <div className="btn-set">
                                                {lottery.status == 'Available' && (
                                                    <>
                                                        {specialLottery.includes(lottery.lottery_id) ?
                                                            <>
                                                                <div className="col-6 p-1">
                                                                    <BlueButton
                                                                        component="label"
                                                                        role={undefined}
                                                                        variant="contained"
                                                                        tabIndex={-1}
                                                                        onClick={() =>
                                                                            handleQuickBuyModelOpen(lottery, 1)
                                                                        }
                                                                        startIcon={<ShoppingCartIcon/>}
                                                                    >
                                                                        Buy
                                                                    </BlueButton>
                                                                </div>
                                                                <div className="col-6 p-1">
                                                                    <BlackButton
                                                                        component="label"
                                                                        role={undefined}
                                                                        variant="outlined"
                                                                        tabIndex={-1}
                                                                        onClick={() =>
                                                                            handleQuickBuyModelOpen(lottery, 2)
                                                                        }
                                                                        startIcon={<SearchIcon/>}
                                                                    >
                                                                        Search
                                                                    </BlackButton>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="col-6 p-1">
                                                                    <BlueButton
                                                                        component="label"
                                                                        role={undefined}
                                                                        variant="contained"
                                                                        tabIndex={-1}
                                                                        onClick={() =>
                                                                            handleQuickBuyModelOpen(lottery, 1)
                                                                        }
                                                                        startIcon={<ShoppingCartIcon/>}
                                                                    >
                                                                        Buy
                                                                    </BlueButton>
                                                                </div>
                                                                <div className="col-6 p-1">
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-evenly"
                                                                        }}>
                                                                        <GoldButton
                                                                            component="label"
                                                                            role={undefined}
                                                                            variant="contained"
                                                                            tabIndex={-1}
                                                                            onClick={() =>
                                                                                handleScheduleModelOpen(lottery)
                                                                            }
                                                                            startIcon={<CreateNewFolderIcon/>}
                                                                        >
                                                                            Subscribe
                                                                        </GoldButton>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 p-1">
                                                                    <BlackButton
                                                                        component="label"
                                                                        role={undefined}
                                                                        variant="outlined"
                                                                        tabIndex={-1}
                                                                        onClick={() =>
                                                                            handleQuickBuyModelOpen(lottery, 2)
                                                                        }
                                                                        startIcon={<SearchIcon/>}
                                                                    >
                                                                        Search
                                                                    </BlackButton>
                                                                </div>
                                                            </>
                                                        }

                                                    </>
                                                )}
                                                {lottery.status == 'Sold Out' && (
                                                    <>
                                                        {lottery.lottery_id == 1 ?
                                                            <>
                                                                <div className="col-6 p-1">
                                                                    <BlueButton
                                                                        disabled
                                                                        component="label"
                                                                        role={undefined}
                                                                        variant="contained"
                                                                        tabIndex={-1}
                                                                        startIcon={<ShoppingCartIcon/>}
                                                                    >
                                                                        {t('Out of Stock')}
                                                                    </BlueButton>
                                                                </div>
                                                                <div className="col-6 p-1">
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-evenly"
                                                                        }}>
                                                                        <GoldButton
                                                                            component="label"
                                                                            role={undefined}
                                                                            variant="contained"
                                                                            tabIndex={-1}
                                                                            onClick={() =>
                                                                                handleScheduleModelOpen(lottery)
                                                                            }
                                                                            startIcon={<CreateNewFolderIcon/>}
                                                                        >
                                                                            Subscribe
                                                                        </GoldButton>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="col-6 p-1">
                                                                    <BlueButton
                                                                        component="label"
                                                                        disabled
                                                                        role={undefined}
                                                                        variant="contained"
                                                                        tabIndex={-1}
                                                                        startIcon={<ShoppingCartIcon/>}
                                                                    >
                                                                        {t('Sold Out')}
                                                                    </BlueButton>
                                                                </div>
                                                                <div className="col-6 p-1">
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-evenly"
                                                                        }}>

                                                                        <GoldButton
                                                                            component="label"
                                                                            role={undefined}
                                                                            variant="contained"
                                                                            tabIndex={-1}
                                                                            onClick={() =>
                                                                                handleScheduleModelOpen(lottery)
                                                                            }
                                                                            startIcon={<CreateNewFolderIcon/>}
                                                                        >
                                                                            Subscribe
                                                                        </GoldButton>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Item>
                                    <div style={{backgroundColor: '#000', height: '20%'}}>
                                        <>
                                            {specialLottery.includes(lottery.lottery_id) ?

                                                <div className="row row-clr detail-wrapper-wrapper">
                                                    <div className='detail-wrapper-wrapper-special'></div>
                                                    <div
                                                        className="col-xs-5 col-sm-5 col-md-5 col-lg-5 content-box">
                                                        <span>{t('Draw No')}</span>
                                                        <h6>{lottery.current_draw_number}</h6>
                                                    </div>
                                                    <div
                                                        className="col-xs-4 col-sm-4 col-md-4 col-lg-4 content-box">
                                                        <span> {t('Draw Date')} </span>
                                                        <h6>
                                                            {lottery.current_draw_date != undefined &&
                                                                dateFormat(
                                                                    lottery?.current_draw_date.replace(
                                                                        ' ',
                                                                        'T'
                                                                    ),
                                                                    'yyyy-mm-dd'
                                                                )}
                                                        </h6>
                                                    </div>
                                                    <div
                                                        className="col-xs-3 col-sm-3 col-md-3 col-lg-3 content-box">
                                                        <span>{t('Draw Close')}</span>
                                                        <h6 className="red">
                                                            <div className="show-counter">
                                                                <CountdownTimer
                                                                    targetDate={lottery.current_end_date}
                                                                />
                                                            </div>
                                                        </h6>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row row-clr detail-wrapper-wrapper">
                                                    <div
                                                        className="col-xs-5 col-sm-5 col-md-5 col-lg-5 content-box">
                                                        <span>{t('Draw No')}</span>
                                                        <h6>{lottery.current_draw_number}</h6>
                                                    </div>
                                                    <div
                                                        className="col-xs-4 col-sm-4 col-md-4 col-lg-4 content-box">
                                                        <span> {t('Draw Date')} </span>
                                                        <h6>
                                                            {lottery.current_draw_date != undefined &&
                                                                dateFormat(
                                                                    lottery?.current_draw_date.replace(
                                                                        ' ',
                                                                        'T'
                                                                    ),
                                                                    'yyyy-mm-dd'
                                                                )}
                                                        </h6>
                                                    </div>
                                                    <div
                                                        className="col-xs-3 col-sm-3 col-md-3 col-lg-3 content-box">
                                                        <span>{t('Draw Close')}</span>
                                                        <h6 className="red">
                                                            <div className="show-counter">
                                                                <CountdownTimer
                                                                    targetDate={lottery.current_end_date}
                                                                />
                                                            </div>
                                                        </h6>
                                                    </div>
                                                </div>

                                            }
                                        </>
                                    </div>
                                </Grid>
                            )) :
                            <div className='loading-screen'>
                                <ReactLoading
                                    type="spinningBubbles"
                                    color="#3978e2"
                                    height={100}
                                    width={50}
                                />
                            </div>
                    }

                </Grid>
            </div>
        );
    }
;

export default withTranslation()(IPayLotteryGrid)
