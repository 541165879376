import React, {useState, useEffect, useRef} from 'react'
import {animateScroll as scroll} from 'react-scroll';
import {useHistory, useLocation} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import {Modal} from 'react-bootstrap'
import BlockUi from 'react-block-ui'
import axios from '../../api/axios'
import useAuth from '../../hooks/useAuth'


const DEFAULT_DIALCODE = process.env.DEFAULT_DIALCODE

const LoginForm = (props: any) => {
    const {t, title, open, handleClose, setOpen} = props
    const navigate = useHistory()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'
    const {setAuth} = useAuth()
    const mobileRef = useRef()
    const errRef = useRef()
    const [mobile, setMobile] = useState('')
    // const [otp, setOtp] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [success, setSuccess] = useState(false)
    const [sentOtp, setSentOtp] = useState(false)
    const [blocking, setBlocking] = useState(false)

    const [timer, setTimer] = useState(60)
    const id = useRef(null)
    const [otp, setOtp] = useState(new Array(4).fill());
    const inputs = useRef([]);

    const clear = () => {
        window.clearInterval(id.current)
    }

    useEffect(() => {
        if (timer === 0) {
            clear()
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        scrollToTop();
    }, [open]);

    const countTimer = () => {
        id.current = window.setInterval(() => {
            setTimer((time) => time - 1);
        }, 1000);
    };

    useEffect(() => {
        setTimer(60)
        countTimer();
        return clear;
    }, [sentOtp]);


    useEffect(() => {
        mobileRef.current?.focus()
        setSentOtp(false);
        setMobile('');
    }, [open])

    useEffect(() => {
        setErrMsg('')
    }, [mobile, otp])

    const handleGoBack = (e: any) => {
        setSentOtp(false);
        setMobile('');
    }

    const handleSubmitStep2 = async (e: any) => {
        setErrMsg()
        let otpNum = parseInt(otp.join(''), 10);

        try {
            if (otpNum.length == 0) {
                setErrMsg(t('Please enter your otp code.'))
                return
            }
            let mobilex = mobile.trim().replace('+', '')

            if (mobilex.startsWith('94')) {
                mobilex = mobilex.substr(2);
            }
            if (mobilex.startsWith('0')) {
                mobilex = mobilex.substr(1);
            }
            setBlocking(true)
            const response = await axios.post(
                'customer_login',
                JSON.stringify({
                    dialcode: '+' + DEFAULT_DIALCODE,
                    telephone: mobilex,
                    // mobilex.substr(0, 1) == DEFAULT_DIALCODE
                    //   ? mobilex.substr(2)
                    //   : parseInt(mobilex),
                    otp_code: otpNum,
                }),
                {
                    headers: {'Content-Type': 'application/json'},
                    // withCredentials: true
                }
            )

            const token = response?.data?.token
            if (token) {
                const user = response?.data?.user
                const permissions = response?.data?.permissions
                const role = response?.data?.role
                const mobiles = response?.data?.mobiles

                localStorage.setItem('atkn', token)
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('mobiles', JSON.stringify(mobiles))
                localStorage.setItem('permissions', JSON.stringify(permissions))
                localStorage.setItem('role', role)
                setAuth({user, permissions, token, role, mobiles})
                if (setOpen) {
                    setOpen(true)
                } else {
                    handleClose()
                    setMobile('');
                    navigate.push(from)
                    scroll.scrollTo(500);
                }
            } else {
                setErrMsg(response.data?.errors?.otp_code)
            }
            setBlocking(false)
            // setSuccess(true);
        } catch (err) {
            setBlocking(false)
            if (!err?.response) {
                setErrMsg(t("Attempts Failed"))
            } else if (err.response?.status === 422) {
                if (err.response.data?.errors?.otp_code)
                    setErrMsg(err.response.data?.errors?.otp_code)
                else setErrMsg(err.response.data?.errors[0])
            } else {
                setErrMsg(err.response.data?.errors?.otp_code)
            }

            errRef.current?.focus()
        }
    }

    const handleSubmitStep1 = async (e: any) => {
        setErrMsg()
        e.preventDefault()
        try {
            let mobilex = mobile.trim().replace('+', '')
            if (mobilex.length == 0) {
                setErrMsg(t('Please enter your mobile number.'))
                return
            }

            setBlocking(true)
            const response = await axios.post(
                'otp/send',
                JSON.stringify({
                    mobile_no:
                        mobilex.substr(0, 2) != '94' ? '94' + parseInt(mobilex) : mobilex,
                }),
                {
                    headers: {'Content-Type': 'application/json'},
                    // withCredentials: true
                }
            )
            setBlocking(false)
            const smsStatus = response?.data?.sms_status
            //  const roles = response?.data?.roles;
            //  setAuth({ mobile, otp, roles, accessToken });
            // setMobile('');
            if (smsStatus.error == 1) {
                setErrMsg(t('OTP Sending Error'))
            } else {
                setSentOtp(smsStatus.success == 1)
            }
            // setSuccess(true);
        } catch (err) {
            setBlocking(false)
            if (!err?.response) {
                setErrMsg(t("Attempts Failed"))
            } else if (err.response?.status === 422) {
                if (err.response.data?.errors?.otp_code)
                    setErrMsg(err.response.data?.errors?.otp_code)
                else setErrMsg(err.response.data?.errors[0])
            } else {
                setErrMsg(err.response.data?.errors?.message)
                errRef.current?.focus()
            }


            errRef.current?.focus()
        }
    }
    const handleChange = (element, index) => {
        if (!/^[0-9]$/.test(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'Tab' && !e.metaKey) {
            e.preventDefault();
        }

        if (e.key === 'Delete' || e.key === 'Backspace') {
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
            if (index > 0) {
                inputs.current[index - 1].focus();
            }
        }

        if (e.key === 'Enter' && otp.every(num => num !== '')) {
            handleVerify();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text').split('');
        if (paste.length === otp.length && /^[0-9]{4}$/.test(paste.join(''))) {
            setOtp(paste);
            inputs.current[otp.length - 1].focus();
        }
    };

    const handleFocus = (e) => {
        e.target.select();
    };

    const handleVerify = () => {
        console.log('Verifying OTP:', otp.join(''));
        handleSubmitStep2(otp.join(''));
    };

    useEffect(() => {
        if (inputs.current[0]) {
            inputs.current[0].focus();
        }
    }, [sentOtp]);

    return (
        <>
            {open &&
                <>
                    <div className="container">
                        <div className="row row-clr register-pop-up">
                            <div className="row heading">
                            </div>
                            <div
                                className="login-container">
                                <div className="login-container-max-width">
                                    <img
                                        src={require('../../assets/images/buy-form/back-button.png')}
                                        onClick={handleClose}
                                        className="img-responsive close-button login-close-icon"
                                        alt="Image"
                                    />
                                    <div className="login-inner-wrapper">
                                        <div
                                            className="login-inner-container">
                                            <header className="login-header-wrapper">
                                                {!sentOtp ? (
                                                        <>
                                                            <h1 className="login-heading">{title}</h1>

                                                            <p className="login-sub-heading">{t('Enter Mobile No')}</p>
                                                        </>)
                                                    :
                                                    (
                                                        <>
                                                            <h1 className="login-heading">{t('Enter verfication code')}</h1>
                                                            <p className="login-sub-heading"> {t('Verification Code Sent', {mobile: mobile})}</p>
                                                        </>
                                                    )
                                                }
                                            </header>
                                            {!sentOtp ?
                                                <form onSubmit={handleSubmitStep1}>
                                                    <div className="row row-clr">
                                                        <h5> {t('Mobile Number')}</h5>
                                                    </div>

                                                    <div className="row row-clr">
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="mobile"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            onChange={(e) => setMobile(e.target.value)}
                                                            defaultValue=""
                                                            value={mobile}
                                                            autoFocus
                                                        />

                                                        {errMsg && (
                                                            <span
                                                                ref={errRef}
                                                                className={errMsg ? 'has-error' : 'offscreen'}
                                                                aria-live="assertive"
                                                            >{errMsg}</span>
                                                        )}
                                                    </div>

                                                    <div className="row row-clr button-wrapper">
                                                        <BlockUi tag="div" blocking={blocking}>
                                                            <button type="submit" className="button-type-7">
                                                                {t('Send verification code')}
                                                            </button>
                                                        </BlockUi>
                                                    </div>
                                                </form>
                                                :
                                                <>
                                                    <form id="otp-form"
                                                          data-aos="fade-left">
                                                        <div className="otp-form-inner-wrapper">
                                                            {otp.map((data, index) => (
                                                                <input
                                                                    key={index}
                                                                    type="text"
                                                                    className="otp-input"
                                                                    maxLength="1"
                                                                    value={data}
                                                                    onChange={e => handleChange(e.target, index)}
                                                                    onKeyDown={e => handleKeyDown(e, index)}
                                                                    onFocus={handleFocus}
                                                                    ref={el => (inputs.current[index] = el)}
                                                                    onPaste={handlePaste}
                                                                />
                                                            ))}
                                                        </div>
                                                        {errMsg && (
                                                            <span
                                                                ref={errRef}
                                                                className={errMsg ? 'has-error' : 'offscreen'}
                                                                aria-live="assertive">
                                                                  {errMsg}
                                                                </span>
                                                        )}
                                                        <BlockUi tag="div" blocking={blocking}>
                                                            <button type="button" className="button-type-7"
                                                                    onClick={handleVerify}>
                                                                {' '}
                                                                {t('Verify')}
                                                            </button>
                                                        </BlockUi>
                                                    </form>
                                                    <div className="otp-resend-container">
                                                        Didn't receive code?{' '}
                                                        <a className="resend-link" href="#0">
                                                            Resend
                                                        </a>
                                                    </div>
                                                    <div className="right" style={{textAlign: 'center'}}>
                                                        <h5
                                                            dangerouslySetInnerHTML={{
                                                                __html: t('Resend verification code', {
                                                                    second: timer,
                                                                }),
                                                            }}
                                                        ></h5>
                                                        <>
                                                            <a className=""
                                                               onClick={() => handleGoBack()}>{t('Go back')}</a>&nbsp;&nbsp;
                                                            <a className="">{t('Resend')}</a>
                                                        </>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default withTranslation()(LoginForm)
