import { withTranslation } from 'react-i18next'

const HowItWorks = ({ t }: any) => {
  return (
    <>
      <section className="how-it-works-section">
        <div className="container">
          <div className="row top-wrapper">
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              data-aos="fade-up"
            >
              <h2>{t('How it works')}</h2>
              <p>{t('Learn how to purchase online Lottery Tickets')}</p>
            </div>
          </div>

          <div className="row content-row"    style={{ border: "2px solid #90B2D9" }}>
            <div 
              data-aos="fade-left"
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4 content-box"
            >
              <img
          
                src={
                  'https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/how_it_works/register.jpeg'
                }
                className="img-responsive"
                alt="Image"
              />
              <h3>{t('Register')}</h3>
              <p>
                {t(
                  'Simply click on Register and submit your mobile number, you will then receive an OTP (One Time Password) for verification. Upon completing the verification your NIC (National Identity Card) details will be requested. This is very important as all winnings will be pegged to the NIC of the respective individual who made the purchase. Ensuring that only you can claim your winnings.'
                )}
              </p>
            </div>

            <div
              data-aos="fade-up"
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4 content-box"
            >
              <img
                src={
                  'https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/how_it_works/get.jpeg'
                }
                className="img-responsive"
                alt="Image"
              />
              <h3>{t('Buy')}</h3>
              <p>
                {t(
                  'Click on your favourite available Lottery Ticket and follow the quick and easy steps to make a purchase. The purchase can be completed in two methods for your convenience. All tickets purchased will be sent as an SMS to the mobile number registered. However, you can view all purchased tickets on your Web Portal at your discretion.'
                )}
              </p>
            </div>

            <div
              data-aos="fade-right"
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4 content-box"
            >
              <img
                src={
                  'https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/how_it_works/win.jpeg'
                }
             
                className="img-responsive"
                alt="Image"
              />
              <h3>{t('Win')}</h3>
              <p>
                {t(
                  'Hooray! You are a winner! Lottery Winnings from Rs 40 to Rs 200 will be added to your Advance Payment Account (My Account) and will be utilised to purchase your next ticket automatically if scheduled or based on your preference for any On-Demand purchase. Any larger winnings will have a different claim mechanism.'
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default withTranslation()(HowItWorks)
