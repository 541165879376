import {lazy, Suspense, useState} from 'react'
import {Switch, Route, useLocation} from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LogoSlider from '../components/LogoSlider'
import Newsletter from '../components/Newsletter'
import MessagePopup from '../components/MessagePopup'

import routes from './config'
import '../assets/css/style.css'
import Home from "../pages/Home";
import Lotteries from "../pages/Lotteries";
import FocusTicket from "../pages/FocusTicket";
import IPayLotteryBuy from "../pages/IPayLotteryBuy";

const Router = () => {
    const [quickOpen, setQuickOpen] = useState(false)
    const [scheduleOpen, setScheduleOpen] = useState(false)
    const [isLoggedOut, setIsLoggedOut] = useState(false)
    const [quickBuyLoginOpen, setQuickBuyLoginOpen] = useState(false)
    const [scheduleBuyLoginOpen, setScheduleBuyLoginOpen] = useState(false)
    const [loginOpen, setLoginOpen] = useState(false)
    const [selectedLottery, setSelectedLottery] = useState({})

    const location = useLocation();

    const hideHeaderFooter = location.pathname.startsWith('/lottery/buy/pay');
    const showLogoSlider = location.pathname.startsWith('/cart');

    console.log('logod slider', !quickOpen && !scheduleOpen && !hideHeaderFooter && !quickBuyLoginOpen && !scheduleBuyLoginOpen && !loginOpen)
    console.log('logod slider showLogoSlider', !quickOpen && !scheduleOpen && !hideHeaderFooter && !quickBuyLoginOpen && !scheduleBuyLoginOpen && !loginOpen || showLogoSlider)
    console.log('logod slider quickOpen', !quickOpen)
    console.log('logod slider scheduleOpen', !scheduleOpen)
    console.log('logod slider hideHeaderFooter', !hideHeaderFooter)
    console.log('logod slider quickBuyLoginOpen', !quickBuyLoginOpen)
    console.log('logod slider scheduleBuyLoginOpen', !scheduleBuyLoginOpen)
    console.log('logod slider loginOpen', !loginOpen)
    console.log('logod slider', '____________________________________')

    return (
        <Suspense fallback={null}>
            {!hideHeaderFooter && (
                <Header setQuickOpen={setQuickOpen}
                        scheduleOpen={scheduleOpen}
                        setScheduleOpen={setScheduleOpen}
                        setIsLoggedOut={setIsLoggedOut}
                        setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                        setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                        selectedLottery={selectedLottery}
                        setLoginOpen={setLoginOpen}
                />
            )
            }
            <h1 hidden>SEO</h1>

            <main>
                {!quickOpen && !scheduleOpen && !hideHeaderFooter && !quickBuyLoginOpen && !scheduleBuyLoginOpen && !loginOpen || showLogoSlider ?
                    <LogoSlider/>
                    : ''
                }
                <Switch>
                    {routes.map((routeItem) => (
                        <Route
                            key={routeItem.component}
                            path={routeItem.path}
                            exact={routeItem.exact}
                            render={(props) =>
                                routeItem.component === 'Home' ? (
                                    <Home
                                        {...props}
                                        quickOpen={quickOpen}
                                        setQuickOpen={setQuickOpen}
                                        scheduleOpen={scheduleOpen}
                                        setScheduleOpen={setScheduleOpen}
                                        selectedLottery={selectedLottery}
                                        setSelectedLottery={setSelectedLottery}
                                        quickBuyLoginOpen={quickBuyLoginOpen}
                                        scheduleBuyLoginOpen={scheduleBuyLoginOpen}
                                        setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                                        setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                                        isLoggedOut={isLoggedOut}
                                        loginOpen={loginOpen}
                                    />
                                ) : routeItem.component === 'Lotteries' ? (
                                        <Lotteries
                                            {...props}
                                            setQuickOpen={setQuickOpen}
                                            quickOpen={quickOpen}
                                            setScheduleOpen={setScheduleOpen}
                                            scheduleOpen={scheduleOpen}
                                            selectedLottery={selectedLottery}
                                            setSelectedLottery={setSelectedLottery}
                                            quickBuyLoginOpen={quickBuyLoginOpen}
                                            scheduleBuyLoginOpen={scheduleBuyLoginOpen}
                                            setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                                            setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                                            isLoggedOut={isLoggedOut}
                                            loginOpen={loginOpen}
                                        />
                                    )
                                    : routeItem.component === 'FocusTicket' ?
                                        <FocusTicket
                                            {...props}
                                            setQuickOpen={setQuickOpen}
                                            quickOpen={quickOpen}
                                            setScheduleOpen={setScheduleOpen}
                                            scheduleOpen={scheduleOpen}
                                            selectedLottery={selectedLottery}
                                            setSelectedLottery={setSelectedLottery}
                                            quickBuyLoginOpen={quickBuyLoginOpen}
                                            scheduleBuyLoginOpen={scheduleBuyLoginOpen}
                                            setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                                            setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                                            isLoggedOut={isLoggedOut}
                                            loginOpen={loginOpen}
                                        />
                                        : routeItem.component === 'IPayLotteryBuy' ?
                                            <IPayLotteryBuy
                                                {...props}
                                                setQuickOpen={setQuickOpen}
                                                quickOpen={quickOpen}
                                                setScheduleOpen={setScheduleOpen}
                                                scheduleOpen={scheduleOpen}
                                                selectedLottery={selectedLottery}
                                                setSelectedLottery={setSelectedLottery}
                                                quickBuyLoginOpen={quickBuyLoginOpen}
                                                scheduleBuyLoginOpen={scheduleBuyLoginOpen}
                                                setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                                                setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                                                isLoggedOut={isLoggedOut}
                                                loginOpen={loginOpen}
                                            />
                                            : (
                                                <Route
                                                    {...props}
                                                    path={routeItem.path}
                                                    exact={routeItem.exact}
                                                    component={lazy(() => import(`../pages/${routeItem.component}`))}
                                                />
                                            )
                            }
                        />
                    ))}

                </Switch>
            </main>
            {/* <Newsletter /> */}
            {/*  <MessagePopup /> */}

            {!hideHeaderFooter && (
                <Footer/>
            )}
        </Suspense>
    )
}

export default Router
