import { withTranslation } from 'react-i18next'

import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined'
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SpeedIcon from '@mui/icons-material/Speed'
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined'

const imageSvg = {
  // filter: 'invert(9%) sepia(99%) saturate(5630%) brightness(60%) contrast(5%)',
  color: '#90B2D9',
  fontSize: 'small',
  height: '35px',
  margin: '0px 0px 15px 0px',
  // width: '75px',
}

const WhyBuyingLottery = ({ t }: any) => {
  return (
    <>
      <section className="why-buying-lottery-section">
        <div className="container">
          <div data-aos="fade-up" className="row top-wrapper">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h2>{t('Why Buy Lotteries from 811 CCWP ?')}</h2>
              <p>{t('Advantages of Buying Lotteries Online')}</p>
            </div>
          </div>

          <div className="row content-row">
            <div className="row row-clr">
              <div
                data-aos="fade-left"
                className="col-xs-12 col-sm-3 col-md-3 col-lg-3 content-box"
              >
                <center>
                  <svg
                    data-testid="DeleteIcon"
                    className="img-responsive"
                    style={imageSvg}
                  >
                    <VerifiedUserOutlinedIcon />
                  </svg>
                </center>
                <h3>{t('Secure')}</h3>
                <p>
                  <b> {t('We are Fort-Knox')}</b>
                  <br />
                  <div className="new-line">
                    The safest method to purchase Lottery Tickets. Each Lottery
                    is pegged to your NIC. Nobody but you can claim the prize.
                  </div>
                </p>
              </div>

              <div
                data-aos="fade-up"
                className="col-xs-12 col-sm-3 col-md-3 col-lg-3 content-box"
              >
                <center>
                  <svg
                    data-testid="DeleteIcon"
                    className="img-responsive"
                    style={imageSvg}
                  >
                    <SpeedIcon />
                  </svg>
                </center>

                <h3>{t('Convenient')}</h3>
                <p>
                  <b> {t('Speed and Efficiency is our game')}</b>
                  <br />
                  <div className="new-line">
                    {t(
                      'A lottery ticket can be purchased via SMS or WEB from anywhere and at any time.'
                    )}
                  </div>
                </p>
              </div>

              <div
                data-aos="fade-up"
                className="col-xs-12 col-sm-3 col-md-3 col-lg-3 content-box"
              >
                <center>
                  <svg
                    data-testid="DeleteIcon"
                    className="img-responsive"
                    style={imageSvg}
                  >
                    <HttpsOutlinedIcon />
                  </svg>
                </center>
                <h3>{t('Privacy')}</h3>
                <p>
                  <b>{t('The cat is in the bag')}</b>
                  <br />
                  <div className="new-line">
                    {t(
                      'We believe any lottery purchase is a deeply personal choice for most and therefore we ensure a safe and private space for you to make your lottery purchase.'
                    )}
                  </div>
                </p>
              </div>

              <div
                data-aos="fade-right"
                className="col-xs-12 col-sm-3 col-md-3 col-lg-3 content-box"
              >
                <center>
                  <svg
                    data-testid="DeleteIcon"
                    className="img-responsive"
                    style={imageSvg}
                  >
                    {' '}
                    <AccessTimeIcon />
                  </svg>
                </center>

                <h3>{t('24/7 Service')}</h3>
                <p>
                  <b>{t('Round the Clock')}</b>
                  <br />
                  <div className="new-line">
                    {t(
                      'Being an E-Lottery Agent, we have the benefit of serving our customers all day long ensuring any moment of luck isn’t missed due to us being unavailable.'
                    )}
                  </div>
                </p>
              </div>
            </div>

            <div className="row row-clr">
              <div
                data-aos="fade-left"
                className="col-xs-12 col-sm-3 col-md-3 col-lg-3 content-box"
              >
                <center>
                  <svg
                    data-testid="DeleteIcon"
                    className="img-responsive"
                    style={imageSvg}
                  >
                    <MarkChatUnreadOutlinedIcon />
                  </svg>
                </center>
                <h3>{t('Free SMS notifications')}</h3>
                <p>
                  <b>{t('It’s the best thing since Sliced Bread')}</b>
                  <br />
                  <div className="new-line">
                    {t(
                      'We notify you of the purchased ticket information, Winning Numbers, Ongoing Promotions and most of all we notify you of all your winnings.All of the above we provide to you our esteemed client, Free of Charge!'
                    )}
                  </div>
                </p>
              </div>

              <div
                data-aos="fade-up"
                className="col-xs-12 col-sm-3 col-md-3 col-lg-3 content-box"
              >
                <center>
                  <svg
                    data-testid="DeleteIcon"
                    className="img-responsive"
                    style={imageSvg}
                  >
                    <QuestionAnswerOutlinedIcon />
                  </svg>
                </center>
                <h3>{t('Free Results')}</h3>
                <p>
                  <b>{t('On the House')}</b>
                  <br />
                  <div className="new-line">
                    {t(
                      'Due to many unforeseen circumstances you may miss the live lottery broadcast, not to worry! We at CCWP 811 send you a free SMS result simply for being customers of our service.'
                    )}
                  </div>
                </p>
              </div>

              <div
                data-aos="fade-up"
                className="col-xs-12 col-sm-3 col-md-3 col-lg-3 content-box"
              >
                <center>
                  <svg
                    data-testid="DeleteIcon"
                    className="img-responsive"
                    style={imageSvg}
                  >
                    <SentimentSatisfiedAltOutlinedIcon />
                  </svg>
                </center>
                <h3>{t('No Lost Tickets')}</h3>
                <p>
                  <b>{t('Nothing to Lose')}</b>
                  <br />
                  <div className="new-line">
                    {t(
                      'Each ticket once purchased can never be lost due to the fact that it is an E-ticket sent as an SMS. That SMS can be resent to your mobile device at your request.'
                    )}
                  </div>
                </p>
              </div>

              <div
                data-aos="fade-right"
                className="col-xs-12 col-sm-3 col-md-3 col-lg-3 content-box"
              >
                <center>
                  <svg
                    data-testid="DeleteIcon"
                    className="img-responsive"
                    style={imageSvg}
                  >
                    <ForestOutlinedIcon />
                  </svg>
                </center>
                <h3>{t('Eco-Friendly')}</h3>
                <p>
                  <b>{t('Cleaner and Greener')}</b>
                  <br />
                  <div className="new-line">
                    {t(
                      'No wasted paper and each ticket purchase help contribute not only to the Sri Lankan Economy but also to reducing our carbon footprint. A brighter future for generations to come.'
                    )}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default withTranslation()(WhyBuyingLottery)
