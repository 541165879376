import React, {useState, useEffect, useRef} from 'react'

import {useHistory, useLocation} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import {Modal} from 'react-bootstrap'
// import Switch from '@mui/material/Switch'
// import RangeSlider from 'react-bootstrap-range-slider'
import {HashLink as Link} from 'react-router-hash-link'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import BlockUi from 'react-block-ui'
import Confetti from 'react-confetti'
import NumberFormat from 'react-number-format'
// import Collapse from '@mui/material/Collapse'
// import Alert from '@mui/material/Alert'
import axios from '../../api/axios'
import useAuth from '../../hooks/useAuth'
import TopupForm from '../../components/TopupForm'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import ReactSpeedometer from 'react-d3-speedometer'
import IsPurchasedConversionTracking from '../IsPurchasedConversionTracking'
import ZodiacDropdown from '../ZodiacDropdown/ZodiacDropdown'
import AriesImage from '../../assets/images/zodiac-signs/ARIES.png'
import TaurusImage from '../../assets/images/zodiac-signs/TAURUS.png'
import GeminiImage from '../../assets/images/zodiac-signs/GEMINI.png'
import CancerImage from '../../assets/images/zodiac-signs/CANCER.png'
import LeoImage from '../../assets/images/zodiac-signs/LEO.png'
import VirgoImage from '../../assets/images/zodiac-signs/VIRGO.png'
import LibraImage from '../../assets/images/zodiac-signs/LIBRA.png'
import ScorpioImage from '../../assets/images/zodiac-signs/SCORPIO.png'
import SagittariusImage from '../../assets/images/zodiac-signs/SAGITTARIUS.png'
import CapricornImage from '../../assets/images/zodiac-signs/CAPRICORN.png'
import AquariusImage from '../../assets/images/zodiac-signs/AQUARIUS.png'
import PiscesImage from '../../assets/images/zodiac-signs/PISCES.png'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import LoginFrom from '../LoginForm'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import {useCart} from '../../context/CartProvider'
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, {AlertProps} from "@mui/material/Alert";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Box} from "@mui/material";
import {use} from "i18next";

const lagna = [
    {value: 'ARIES', image: AriesImage},
    {value: 'TAURUS', image: TaurusImage},
    {value: 'GEMINI', image: GeminiImage},
    {value: 'CANCER', image: CancerImage},
    {value: 'LEO', image: LeoImage},
    {value: 'VIRGO', image: VirgoImage},
    {value: 'LIBRA', image: LibraImage},
    {value: 'SCORPIO', image: ScorpioImage},
    {value: 'SAGITTARIUS', image: SagittariusImage},
    {value: 'CAPRICORN', image: CapricornImage},
    {value: 'AQUARIUS', image: AquariusImage},
    {value: 'PISCES', image: PiscesImage},
]

// import { isEmptyArray } from 'formik'
const regex3 = /^([0-9]{12})/
const regex4 = /^([0-9]{9})([v|x|V|X])/

const PAGE_COUNT = process.env.TICKET_PAGE_COUNT
const MAX_TICKET_COUNT = process.env.MAX_TICKET_COUNT
const DEFAULT_DIALCODE = process.env.DEFAULT_DIALCODE
const DEFAULT_TICKET_COUNT = process.env.DEFAULT_TICKET_COUNT
const MINIMUM_TOPUP_AMOUNT = process.env.MINIMUM_TOPUP_AMOUNT
const CONVENIENT_FEE = parseFloat(process.env.CONVENIENT_FEE)

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const QuickBuyForm = (props: any) => {
    const {
        t,
        title,
        quickOpen,
        handleClose,
        lottery,
        quickBuyLoginOpen,
        selectedTab,
        paymentReference,
        isLoggedOut,
    } = props
    // console.log(lottery.name)

    const navigate = useHistory()
    const location = useLocation()
    const axiosPrivate = useAxiosPrivate()
    const from = location.state?.from?.pathname || '/'
    const {setAuth, auth} = useAuth()
    //const mobileRef = useRef()
    const errRef = useRef()
    const [mobile, setMobile] = useState('')
    const [open, setOpen] = useState(false)
    const [auto, setAuto] = useState(false)
    const [nicno, setNicNo] = useState('')
    // const [otp, setOtp] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [severity, setSeverity] = useState('')
    const [userMobiles, setUserMobiles] = useState([])
    const [userMobile, setUserMobile] = useState('')
    const [sentOtp, setSentOtp] = useState(false)
    const [mobileNo, setMobileNo] = useState('')
    const [draws, setDraws] = useState([])
    const [lotteryFormat, setLotteryFormat] = useState([])
    const [user, setUser] = useState({})
    const [ticketCount, setTicketCount] = useState(DEFAULT_TICKET_COUNT)
    const [drawNo, setDrawNo] = useState(0)
    const [ticketList, setticketList] = useState([])
    const [paymentOption, setPaymentOption] = useState(paymentReference ? 3 : 2)
    const [perchaseSuccess, setPerchaseSuccess] = useState(false)
    const [ticketDetails, setTicketDetails] = useState({})
    const [pointDetail, setPointsDetails] = useState({})
    const [token, setToken] = useState('')
    const [tab, setTab] = useState(selectedTab)
    const [searchArray, setSearchArray] = useState([])
    const [formatArray, setFormatArray] = useState([])
    const [ticketResult, setTicketResult] = useState([])
    const [alreadyLockedTickets, setAlreadyLockedTickets] = useState([])
    const [selectedTickets, setSelectedTickets] = useState([])
    const [mortTicketAvail, setMortTicketAvail] = useState(false)
    const [ticketPageCount, setTicketPageCount] = useState(0)
    const [ticketMessage, setTicketMessage] = useState('')
    const [sliderCount, setSliderCount] = useState(0)
    const [lockMessage, setLockMessage] = useState('')
    const [accountBalance, setAccountBalance] = useState(0)
    const [accountBalanceAlert, setAccountBalanceAlert] = useState(false)
    const [nicErrMsg, setNIcErrMsg] = useState('')
    const [blocking, setBlocking] = useState(false)
    const [formatBlocking, setFormatBlocking] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [topUpOpen, setTopUpOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [ticketReqeust, setTicketReqeust] = useState({})
    const [transaction, setTransaction] = useState({})
    const [userFavourites, setUserFavourites] = useState({})
    const [save, setSave] = useState({})
    const [openFavMsg, setOpenFavMsg] = useState(false)
    const [resultBlocking, setResultBlocking] = useState(false)
    const [msgFavourite, setMsgFavourite] = useState('')
    const [favouriteSeverity, setFavouriteSeverity] = useState('')
    const [ticketMeter, setTicketMeter] = useState(5)
    const [ticketQty, setTicketQty] = useState(PAGE_COUNT)
    const [quantity, setQuantity] = useState(0)
    const [pageNo, setPageNo] = useState(0)
    const resultEndRef = useRef(null)
    const nicRef = useRef(null)
    const [paymentDisable, setpaymentDisable] = useState(false)
    const [customerPremium, setCustomerPremium] = useState(0.0)
    const [ccwpPremium, setCcwpPremium] = useState(0.0)
    const [ceylincoOnoActiveStatus, setCeylincoOnoActiveStatus] = useState(auto)
    const [selectedOption, setSelectedOption] = useState(null)
    const {
        cart,
        setCart,
        removeFromCartByTicketId,
        resetCart,
        addToQuickBuy,
        setCartStartTime,
        cartStartTime,
        setCartEndTime,
        tabId,
        setTabId,
        uniqueId,
        setCustomerPremiumC,
        setCcwpPremiumC,
        setCeylincoOnoActiveStatusC
    } = useCart()

    const scrollToTicketNumber = () => {
        const element = document.querySelector('.result-wrapper')
        if (element) {
            const elementRect = element.getBoundingClientRect()
            const absoluteElementTop = elementRect.top + window.pageYOffset
            const middle =
                absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2

            window.scrollTo({
                top: middle,
                behavior: 'smooth',
            })
        }
    }

    const scrollToShowConfirmation = () => {
        const element = document.querySelector('.confirm-wrapper')
        if (element) {
            const elementRect = element.getBoundingClientRect()
            const absoluteElementTop = elementRect.top + window.pageYOffset
            const middle =
                absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2

            window.scrollTo({
                top: middle,
                behavior: 'smooth',
            })
        }
    }

    const scrollToNICError = () => {
        const element = document.getElementById('nic-error');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    useEffect(() => {
        scrollToNICError();
    }, [nicErrMsg]);

    useEffect(() => {
        if (quickOpen === true) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [quickOpen])

    useEffect(() => {
        if (isLoggedOut === true) {
            setOpen(false)
        }
    }, [isLoggedOut])

    const [timer, setTimer] = useState(60)
    const id = useRef(null)
    const clear = () => {
        window.clearInterval(id.current)
    }

    const countTimer = () => {
        id.current = window.setInterval(() => {
            setTimer((time) => time - 1)
        }, 1000)

        return () => clear()
    }

    useEffect(() => {
        if (timer === 0) {
            clear()
        }
    }, [timer])

    useEffect(() => {
        if (pageNo > 0 && quantity > 0) {
            handleSearch(pageNo, quantity)
        }
    }, [pageNo, quantity])

    useEffect(() => {
        if (tab == 2) {
            handleSearch(pageNo, quantity)
        }
    }, [tab])

    useEffect(() => {
        if (lottery.lottery_id == 24) {
            setpaymentDisable(false)
        } else {
            setpaymentDisable(true)
        }
        // console.log(user.id)
        const userx = JSON.parse(localStorage.getItem('user'))
        const atkn = localStorage.getItem('atkn')
        // setUserMobiles(mobiles)
        setErrMsg('')
        setTopUpOpen(false)
        setUser(userx)
        setToken(atkn)

        // setUserMobile(mobiles && mobiles[0]?.mobile_no)

        //setMobileNo(mobiles && mobiles[0]?.mobile_no)

        userx != undefined ? getUserBalance(userx.id) : ''
        userx != undefined ? getCeylincoActiveStatus(userx.id) : ''
        setOpenFavMsg(false)
        setTicketMeter(5)
        setTicketResult([])
        setAlreadyLockedTickets([])
        setFormatArray([])
        setPerchaseSuccess(false)
        setAccountBalanceAlert(false)
        setAccountBalance(0)
        fetchCurrentDraws(atkn)
        setSelectedTickets([])
        if (selectedTab !== 2) {
            setTab(1)
        } else {
            setTab(2)
        }
        setTicketMessage(0)
        setTicketCount(DEFAULT_TICKET_COUNT)
        fetchUserMobiles(atkn, userx)
        setShowConfirm(false)
        atkn != undefined ? getUserFavourites(atkn) : ''
        // console.log("atkn", atkn)
        // user != undefined ? : ''
        // user != null && Object.keys(user).length != 0 ? getCeylincoActiveStatus(user.id) : ''
        user != null && Object.keys(user).length != 0 ? getUserFavourites(atkn) : ''
    }, [open, quickOpen])

    useEffect(() => {
        setPerchaseSuccess(false)
    }, [])

    useEffect(() => {
        setNIcErrMsg('')
    }, [nicno])

    useEffect(() => {
        setErrMsg('')
    }, [mobile, paymentOption, ticketCount])

    useEffect(() => {
        setDrawNo(draws[0]?.id)

        setAccountBalanceAlert(false)

        user != undefined ? getCeylincoActiveStatus(user.id) : ''
        user.id != undefined ? getUserBalance(user.id) : ''
    }, [draws])

    const getUserFavourites = async (token: any) => {
        try {
            const response = await axiosPrivate.post('/lottery/favourite/list', {})

            setUserFavourites(response?.data)

            fetchLotteryFormats(token)
        } catch (err) {
            fetchLotteryFormats(token)

            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors)
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const handleAlertClose = () => {
        setOpenAlert(false)
    }

    const fetchLotteryFormats = async (token: any) => {
        setErrMsg('')

        if (lottery.lottery_id == undefined || token == undefined) return false

        setFormatBlocking(true)

        try {
            const response = await axiosPrivate.get(
                'lottery/number/format/' + lottery.lottery_id,

                {}
            )

            const format = response?.data?.format

            let ser: any = [],
                fm: any = [],
                i = 0

            const formatArray: any = Object.keys(format).map(function (index) {
                const f = format[index]

                ser[f] = userFavourites[f]
                    ? f.substr(0, 1) == 'n'
                        ? parseInt(userFavourites[f])
                        : userFavourites[f]
                    : ''

                fm[i] = f

                i++

                return f
            })

            setFormatBlocking(false)
            setSearchArray(ser)
            setFormatArray(fm)

            setLotteryFormat(formatArray)
        } catch (err) {
            setFormatBlocking(false)

            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                if (err.response.data?.errors?.otp_code)
                    setErrMsg(err.response.data?.errors?.otp_code)
                else setErrMsg(err.response.data?.errors[0])
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const fetchCurrentDraws = async (token) => {
        console.log('fetchedData')
        setErrMsg('')

        if (lottery.lottery_id == undefined || token == undefined) return false

        try {
            const response = await axiosPrivate.get(
                'draws/active/' + lottery.lottery_id,

                {}
            )

            const curDrows = response?.data?.draws

            setDraws(curDrows)
        } catch (err) {
            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors[0])
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const fetchUserMobiles = async (token: any, user: any) => {
        setErrMsg('')

        if (lottery.lottery_id == undefined || token == undefined) return false

        try {
            const response = await axiosPrivate.get(
                '/user/mobiles/view/' + user.id,

                {}
            )

            const userMobiles = response?.data?.mobiles

            setUserMobiles(userMobiles)

            if (userMobiles[0]) setUserMobile(userMobiles[0])

            // setDraws(curDrows)
        } catch (err) {
            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors[0])
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const getUserBalance = async (id: any) => {
        if (id == undefined) return
        500
        try {
            const response = await axiosPrivate.get('user/apcredit/balance/' + id, {})

            let balance = parseFloat(response?.data?.acccount_balance)
            if (balance) setAccountBalance(balance)
            else balance = 0

            if (
                ((tab == 1 &&
                        balance < lottery.ticket_price * ticketCount &&
                        balance < MINIMUM_TOPUP_AMOUNT) ||
                    (tab != 1 &&
                        balance < lottery.ticket_price * selectedTickets.length &&
                        balance < MINIMUM_TOPUP_AMOUNT)) &&
                tab == 1 &&
                paymentOption !== 3
            ) {
                setTopUpOpen(true)

                setAccountBalanceAlert(true)
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors)
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const handleCardSaving = async (e: any) => {
        const isChecked = e.target.checked

        if (isChecked) handleUpdateFavourites()
        else setOpenFavMsg(false)
    }

    const handlePaymenOptionOnChange = (value: any) => {
        setPaymentOption(value)
        setShowConfirm(false)

        if (value == (1 || 3)) {
            setTopUpOpen(false)
        } else if (
            (tab == 1 && accountBalance < lottery.ticket_price * ticketCount) ||
            (tab != 1 &&
                accountBalance < lottery.ticket_price * selectedTickets.length)
        ) {
            setTopUpOpen(true)
        }
    }

    const handleUpdateNicNo = async (postArr: any) => {
        if (nicno.length < 10) {
            setBlocking(false)
            return false
        }

        try {
            const response = await axiosPrivate.post('user/edit/nic', {
                id: user.id,

                nic: nicno,
            })

            const newUser = response?.data?.user

            if (newUser) {
                localStorage.setItem('user', JSON.stringify(newUser))

                setAuth({user: newUser})
                if (topUpOpen) {
                    setTicketReqeust(postArr)
                } else directBuyTickets(postArr)
            }
        } catch (err) {
            setBlocking(false)
            if (!err?.response) {
                setNIcErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                if (Array.isArray(err.response.data?.errors))
                    setNIcErrMsg(err.response.data?.errors[0])
                else setNIcErrMsg(err.response.data?.errors)

                nicRef.current?.focus()
            } else {
                setNIcErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const handleUpdateFavourites = async () => {
        try {
            const response = await axiosPrivate.post(
                'lottery/favourite/add',
                searchArray
            )

            setSave(true)
            setOpenFavMsg(true)
            setMsgFavourite(t('Your favourite numbers has been saved successfully.'))
            setFavouriteSeverity('success')
        } catch (err) {
            setFavouriteSeverity('warning')

            if (!err?.response) {
                setMsgFavourite(t('No Server Response'))
            } else if (err.response?.status === 422) {
                if (Array.isArray(err.response.data?.errors))
                    setMsgFavourite(err.response.data?.errors[0])
                else setMsgFavourite(err.response.data?.errors)
            } else {
                setMsgFavourite(err.response.data?.errors?.message)
            }
        }
    }

    const handleBuyMoreTickets = () => {
        setTicketResult([])
        user != undefined ? getUserBalance(user.id) : ''
        user != undefined ? getCeylincoActiveStatus(user.id) : ''
        setPerchaseSuccess(false)
    }

    const handleBuyTickets = (tickets: any, transaction: any) => {
        setBlocking(false)
        setPerchaseSuccess(true)
        resetCart()
        setAccountBalanceAlert(false)
        setTopUpOpen(false)
        setTransaction(transaction)
        setTicketDetails(transaction)
    }

    const handleTopupClose = () => {
        setTopUpOpen(false)
    }

    const handleAddToCart = () => {
        ticketBulkLock(lottery.lottery_id, drawNo, parseInt(ticketCount), 'quick_buy');
    };

    const ticketBulkLock = async (lotteryId, drawId, quantity, source) => {
        try {
            const response = await axiosPrivate.post('ticket_bulk_qty_enough_test/lock',
                {lottery_id: lotteryId, draw_id: drawId, quantity: quantity, user_id: user.id, tab_id: uniqueId}
            )

            console.log('removed', response.data.available_qty)

            if (response.data.available_qty !== undefined) {
                if (response.data.available_qty > 0) {
                    setTicketCount(response.data.available_qty)
                    setBlocking(true)
                    setShowConfirm(true)
                    scrollToShowConfirmation()
                    setBlocking(false)
                }
                setErrMsg('Only ' + response.data.available_qty + ' tickets are available')
            } else {
                setShowConfirm(false)
                setErrMsg('')
                addToQuickBuy(lottery.lottery_id, drawNo, parseInt(ticketCount), 'quick_buy');
                setOpenAlert(true)
                setSeverity('success')
                setSuccessMsg(ticketCount + ' Ticket Added to the Cart')
            }

            return response;
        } catch (err) {

            if (!err?.response) {
                setLockMessage(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setLockMessage(err.response.data?.errors)
                // setTicketResult(ticketResult.filter((tkt) => tkt. != item[i]));
            } else {
                setLockMessage(err.response.data?.errors?.message)
            }
        }
    }

    const handleSubmitBuyTicket = (e: any) => {
        e.preventDefault()

        if (user.nicno == null && nicno.length < 10) {
            setNIcErrMsg(t('*Please enter valid NIC number.'))
            setBlocking(false)
            return false
        }
        if (nicno == null && nicno.length < 10) {
            setNIcErrMsg(t('*Please enter valid NIC number.'))
            setBlocking(false)
            return false
        }
        // console.log("regex3.test(nicno)", regex3.test(nicno))
        // console.log("regex4.test(nicno)", regex4.test(nicno))
        // console.log("regex3.test(user.nicno)", regex3.test(user.nicno))
        // console.log("regex4.test(user.nicno)", regex4.test(user.nicno))

        if (
            regex3.test(nicno) == false &&
            regex4.test(nicno) == false &&
            regex3.test(user.nicno) == false &&
            regex4.test(user.nicno) == false
        ) {
            setNIcErrMsg('*Please enter valid NIC number.')
            setBlocking(false)
            return false
        }

        const isTickets = tab == 2 && selectedTickets.length > 0

        const total = isTickets
            ? paymentOption == 2
                ? lottery.ticket_price * selectedTickets.length +
                lottery.ticket_price *
                selectedTickets.length *
                (ceylincoOnoActiveStatus == 1 && ceylincoOnoActiveStatus != null
                    ? customerPremium / 100
                    : 0)
                : lottery.ticket_price * selectedTickets.length
            : paymentOption == 2
                ? lottery.ticket_price * ticketCount +
                lottery.ticket_price *
                ticketCount *
                (ceylincoOnoActiveStatus == 1 && ceylincoOnoActiveStatus != null
                    ? customerPremium / 100
                    : 0)
                : lottery.ticket_price * ticketCount

        // console.log("total=>>", total)
        const count = isTickets ? selectedTickets.length : ticketCount
        //Hconsole.log('length1', selectedTickets.length)

        const ticketsNew = []
        // console.log('Payment Option', paymentOption)
        // console.log('topup', topUpOpen)
        // console.log('total', total)
        // console.log('ap account', accountBalance)

        if (paymentOption == 2 && !topUpOpen && total > accountBalance) {
            setErrMsg('Your account has insufficient credit.')
            setTopUpOpen(true)
            return false
        }

        setBlocking(true)

        if (isTickets) {
            for (const i in selectedTickets) {
                ticketsNew.push({id: selectedTickets[i]})
            }
        }
        const postArr = {
            total: total,
            credits: [
                {
                    amount: paymentOption == 1 ? 0 : total,
                    type: paymentReference ? 'ipay' : 'ap_account',
                },
            ],
            lottery: lottery.lottery_id,
            tickets: isTickets ? ticketsNew : ticketList,
            quantity: count,
            draw_id: drawNo,
            channel: 'web',
            mobile_no:
                userMobile.substr(0, 2) == DEFAULT_DIALCODE
                    ? userMobile.substr(2)
                    : userMobile,
        }

        if (topUpOpen) {
            if (user.nicno == null && nicno.length > 9) {
                handleUpdateNicNo(postArr)
            } else setTicketReqeust(postArr)
            return
        }

        if (user.nicno == null && nicno.length > 9) {
            handleUpdateNicNo(postArr)
        } else directBuyTickets(postArr)
    }

    const directBuyTickets = async (postArr: any) => {
        try {
            const response = await axiosPrivate.post(
                'user/tickets/buy/' + user.id,

                postArr
            )

            const transaction = response?.data?.transaction

            const points = response?.data?.points

            setPerchaseSuccess(true)

            resetCart()

            setTransaction([])

            setBlocking(false)

            setTicketDetails(transaction)
            setPointsDetails(points)
            setAccountBalanceAlert(false)

            setTopUpOpen(false)
        } catch (err) {
            setBlocking(false)

            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors)
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    // Commented By LashenDEV
    // const handleSubmitStep2 = async (e: any) => {
    //     e.preventDefault()
    //
    //     let otpNum = parseInt(otp.join(''), 10);
    //
    //     try {
    //         if (otpNum.length == 0) {
    //             setErrMsg(t('Please enter your otp code.'))
    //
    //             return
    //         }
    //
    //         let mobilex = mobile.trim().replace('+', '')
    //
    //         setBlocking(true)
    //
    //         const response = await axios.post(
    //             'customer_login',
    //
    //             JSON.stringify({
    //                 dialcode: '+' + DEFAULT_DIALCODE,
    //
    //                 telephone:
    //                     mobilex.substr(0, 1) == DEFAULT_DIALCODE
    //                         ? mobilex.substr(2)
    //                         : parseInt(mobilex),
    //
    //                 otp_code: otpNum,
    //             }),
    //
    //             {
    //                 headers: {'Content-Type': 'application/json'},
    //
    //                 // withCredentials: true
    //             }
    //         )
    //
    //         const tokenx = response?.data?.token
    //
    //         if (tokenx) {
    //             const userx = response?.data?.user
    //
    //             const permissions = response?.data?.permissions
    //
    //             const mobiles = response?.data?.mobiles
    //
    //             const role = response?.data?.role
    //
    //             localStorage.setItem('atkn', tokenx)
    //
    //             localStorage.setItem('user', JSON.stringify(userx))
    //
    //             localStorage.setItem('mobiles', JSON.stringify(mobiles))
    //
    //             localStorage.setItem('permissions', JSON.stringify(permissions))
    //
    //             localStorage.setItem('role', role)
    //
    //             setToken(tokenx)
    //             setOpen(true)
    //
    //             setQuickOpen(true)
    //
    //             setUser(userx)
    //
    //             setAuth({user, permissions, token, role, mobiles})
    //
    //             fetchCurrentDraws(tokenx)
    //
    //             fetchUserMobiles(tokenx, userx)
    //
    //             getUserFavourites(tokenx)
    //
    //             setPerchaseSuccess(false)
    //         } else {
    //             setErrMsg(response.data?.errors?.otp_code)
    //         }
    //
    //         setBlocking(false)
    //     } catch (err) {
    //         setBlocking(false)
    //
    //         if (!err?.response) {
    //             setErrMsg(t('No Server Response'))
    //         } else if (err.response?.status === 422) {
    //             if (err.response.data?.errors.otp_code)
    //                 setErrMsg(err.response.data?.errors.otp_code)
    //             else setErrMsg(err.response.data?.errors[0])
    //         } else {
    //             setErrMsg(err.response.data?.errors?.otp_code)
    //         }
    //
    //         errRef.current?.focus()
    //     }
    // }
    //
    // const handleSubmitStep1 = async (e: any) => {
    //     e.preventDefault()
    //
    //     try {
    //         let mobilex = mobile.trim().replace('+', '')
    //
    //         if (mobilex.length == 0) {
    //             setErrMsg(t('Please enter your mobile number.'))
    //
    //             return
    //         }
    //
    //         setBlocking(true)
    //
    //         const response = await axios.post(
    //             'otp/send',
    //
    //             JSON.stringify({
    //                 mobile_no:
    //                     mobilex.substr(0, 2) != DEFAULT_DIALCODE
    //                         ? DEFAULT_DIALCODE + parseInt(mobilex)
    //                         : mobilex,
    //             }),
    //
    //             {
    //                 headers: {'Content-Type': 'application/json'},
    //
    //                 // withCredentials: true
    //             }
    //         )
    //
    //         const smsStatus = response?.data?.sms_status
    //
    //         setBlocking(false)
    //
    //         if (smsStatus.error == 1) {
    //             setErrMsg(t('OTP Sending Error'))
    //         } else {
    //             setSentOtp(smsStatus.success == 1)
    //
    //             countTimer()
    //         }
    //     } catch (err) {
    //         setBlocking(false)
    //
    //         if (!err?.response) {
    //             setErrMsg(t('No Server Response'))
    //         } else if (err.response?.status === 422) {
    //             setErrMsg(err.response.data?.errors?.attempts)
    //         } else {
    //             setErrMsg(err.response.data?.errors?.message)
    //         }
    //
    //         errRef.current?.focus()
    //     }
    // }

    const handleSelectTicketCount = (qty: number) => {
        // alert(qty)

        setTicketCount(qty)

        if (qty <= 20) {
            setTicketMeter(qty)
        } else {
            setTicketMeter(20)
        }
        //Hconsole.log('length2', selectedTickets.length)

        if (
            (tab == 1 &&
                accountBalance < lottery.ticket_price * qty &&
                paymentOption == 2) ||
            (tab != 1 &&
                accountBalance < lottery.ticket_price * selectedTickets.length &&
                paymentOption == 2)
        ) {
            setTopUpOpen(true)
        } else {
            setTopUpOpen(false)
        }
        setTicketCount(qty)
    }

    const handleTabSelect = (tab: number) => {
        if (tab == 1) setTicketCount(DEFAULT_TICKET_COUNT)
        else setTicketCount(0)

        setTab(tab)
    }

    const handleSearchArray = (format: any, val: any) => {
        setTicketMessage('')

        setSearchArray({...searchArray, [format]: val})
    }

    const handleCheckAll = async (e) => {
        //Hconsole.log('Begin Function handleCheckAll')

        const isChecked = e.target.checked
        //Hconsole.log('isChecked', isChecked)

        let m = 0

        const y: any = []
        // let x: any = []
        setResultBlocking(true)

        const selectedTickets = []

        ticketResult.forEach(ticket => {
            const isItemInCart = cart.some(cartItem => cartItem.id === ticket.ticket_id);

            console.log('removed', ticket.ticket_id)
            console.log('removed', cart)
            if (isItemInCart) {
                selectedTickets.push(ticket.ticket_id);
            }
        });

        setCart(cart.filter(item => !selectedTickets.includes(item.id)));


        if (isChecked && selectedTickets) {
            console.log(' isChecked   ticketResult3:', ticketResult)

            for (var k in ticketResult) {
                console.log(' Begin for (var k in ticketResult)')
                //Hconsole.log('   k:', k)

                if (ticketResult[k]['checked']) {
                    //Hconsole.log('     Inside if (ticketResult[k][checked] && ticketResult[k][availability_status] == true) ')
                    console.log(ticketResult[k]['checked'])
                    continue
                }
                y[m] = ticketResult[k].ticket_id
                m++
                //Hconsole.log('   ischecked y[m]  ', y[m])
                //Hconsole.log('   ischecked m++', m++)
                console.log(' End for (var k in ticketResult)')
            }

            //Hconsole.log('isChecked Inside Check All alreadyLockedTicket ', alreadyLockedTickets)
            //Hconsole.log("ischecked y before remove", y)
            const z = y.filter((element) => !alreadyLockedTickets.includes(element))
            //Hconsole.log("ischecked y after remove", z)
            await ticketLock(y, 'lock', 0)
            //Hconsole.log(' isChecked   ticketResult4:', ticketResult)
        } else {
            //Hconsole.log(' isUnChecked   ticketResult3:', ticketResult)

            for (var k in ticketResult) {
                //Hconsole.log('  Begin for (var k in ticketResult)')
                //Hconsole.log('   k:', k)

                y[m] = ticketResult[k].ticket_id //add to array

                m++

                //Hconsole.log('   isunchecked y[m]  ', y[m])
                //Hconsole.log('   isunchecked m++', m++)
            }
            //Hconsole.log("   isunchecked y", y)

            //Hconsole.log('isUnChecked Inside Check All alreadyLockedTicket ', alreadyLockedTickets)
            //Hconsole.log("isUnchecked y before remove", y)
            const z = y.filter((element) => !alreadyLockedTickets.includes(element))
            //Hconsole.log("isUnchecked y after remove", z)

            await ticketLock(z, 'unlock', 0)

            //Hconsole.log(' isUnChecked   ticketResult4:', ticketResult)

            //Hconsole.log('  End for (var k in ticketResult)')
        }
    }

    const handleCountComplete = (e: any) => {
        let x,
            m = 0

        const y: any = []
        //Hconsole.log('length3', selectedTickets.length)

        if (sliderCount > selectedTickets.length) {
            x = sliderCount - selectedTickets.length

            for (const k in ticketResult) {
                if (ticketResult[k]['checked'] == true) continue

                y[m] = ticketResult[k].ticket_id

                m++
            }

            ticketLock(y, 'lock', x)
        } else {
            //Hconsole.log('length4', selectedTickets.length)

            x = selectedTickets.length - sliderCount

            for (const i in selectedTickets) {
                if (y.length >= x) break

                y[i] = selectedTickets[i]
            }

            ticketLock(y, 'unlock', 0)
        }
    }

    const ticketLock = async (itm: any, opt: string, opc: number) => {
        try {
            const item = Array.isArray(itm) ? itm : [itm]
            //Hconsole.log('item', item)
            setResultBlocking(true)
            const response = await axiosPrivate.post(
                opt == 'lock' ? 'ticket/lock' : 'ticket/unlock',

                JSON.stringify({id: item, tabId: uniqueId, user_id: user.id}),

                {
                    headers: {'Content-Type': 'application/json'},

                    // withCredentials: true
                }
            )

            setResultBlocking(false)
            const result: any = response?.data.data
            const newArr: any = []
            let count = selectedTickets.length
            //Hconsole.log('selectedTickets1', selectedTickets)
            const tempSelectTicket = [...selectedTickets]

            let multi = opc
            //Hconsole.log("multi", multi)
            //Hconsole.log("opc", opc)

            //Hconsole.log("Begin Loop1")

            for (const j in result) {
                if (opc > 0 && multi == 0) break
                //Hconsole.log(" multi2", multi)
                //Hconsole.log(" opc2", opc)

                for (const k in ticketResult) {
                    //Hconsole.log("  Begin Loop2")

                    newArr[k] = ticketResult[k]

                    //Hconsole.log("    multi3", multi)
                    //Hconsole.log("    opc3", opc)

                    if (ticketResult[k]['ticket_id'] == result[j]['id']) {
                        if (opt == 'lock') {
                            newArr[k]['checked'] = true

                            newArr[k]['blocked'] = false

                            //Hconsole.log('    result[j][availability_status]:', result[j]['availability_status']);
                            newArr[k]['availability_status'] =
                                result[j]['availability_status']
                            //Hconsole.log('      Ticket ID:', newArr[k]['id']);
                            //Hconsole.log('      Availability Status:', newArr[k]['availability_status']);
                            //Hconsole.log('      Disabled:', newArr[k]['blocked'] || newArr[k]['availability_status'] === 'false');

                            //if checked ticket is alreday locked by someone this will remove that ticket id from selected ticket list
                            if (newArr[k]['availability_status'] == 'false') {
                                const index = tempSelectTicket.indexOf(
                                    ticketResult[k]['ticket_id']
                                )
                                //Hconsole.log("        index", index)
                                //Hconsole.log('        checked tempSelectTicket1', tempSelectTicket)
                                // setSelectedTickets(selectedTickets => [...selectedTickets, ticketResult[k]['ti
                                // if (index > -1) {
                                //   // only splice array when item is found
                                //   console.log("Betta3")
                                //   tempSelectTicket.splice(index, 1) // 2nd parameter means remove one item only
                                // }
                                //Hconsole.log('Before push alreadyLockedTickets:', alreadyLockedTickets)

                                alreadyLockedTickets.push(ticketResult[k]['ticket_id'])

                                //Hconsole.log('After push alreadyLockedTickets:', alreadyLockedTickets)
                                // tempSelectTicket.splice(index, 1) // 2nd parameter means remove one item only

                                continue
                            }

                            if (opc > 0) multi--

                            tempSelectTicket.push(ticketResult[k]['ticket_id'])
                            //Hconsole.log('      checked tempSelectTicket2', tempSelectTicket)
                            // setSelectedTickets(selectedTickets => [...selectedTickets, ticketResult[k]['ticket_id']]);

                            count++
                        } else if (opt == 'unlock') {
                            //Hconsole.log('unlock availabilty', newArr[k]['availability_status'])
                            // newArr[k]['availability_status'] = result[j]['availability_status'];
                            newArr[k]['checked'] = false

                            newArr[k]['blocked'] = false

                            // newArr[k]['availability_status'] = result[j]['availability_status'];
                            //Hconsole.log('unlock availabilty2', newArr[k]['availability_status'])
                            // if (result[j]['availability_status'] !== undefined) {
                            //   newArr[k]['availability_status'] = result[j]['availability_status'];
                            // } else {

                            //   console.warn('Unexpected availability_status value:', newArr[k]['availability_status']);
                            // }

                            const index = tempSelectTicket.indexOf(
                                ticketResult[k]['ticket_id']
                            )

                            //Hconsole.log('      unchecked tempSelectTicket', tempSelectTicket)

                            if (index > -1) {
                                // only splice array when item is found

                                tempSelectTicket.splice(index, 1) // 2nd parameter means remove one item only
                            }

                            //tempSelectTicket.filter(

                            // (tkt) => tkt != ticketResult[k]['ticket_id']

                            // )

                            count--
                        }
                    }

                    //Hconsole.log("  End Loop2")
                }
            }

            //Hconsole.log("End Loop1")

            //Hconsole.log('length5', tempSelectTicket.length)

            if (tempSelectTicket.length > 0) {
                const selectedTicketsTemp = Array.from(new Set(tempSelectTicket))
                setSelectedTickets(selectedTicketsTemp)
                addSelectedTicketsToCart(selectedTicketsTemp)
            } else setSelectedTickets([])

            setSliderCount(count)

            setTicketResult(newArr)
            setResultBlocking(false)
            if (accountBalance < lottery.ticket_price * selectedTickets.length)
                setTopUpOpen(true)
            else setTopUpOpen(false)
        } catch (err) {
            setResultBlocking(false)

            if (!err?.response) {
                setLockMessage(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setLockMessage(err.response.data?.errors)

                // setTicketResult(ticketResult.filter((tkt) => tkt. != item[i]));
            } else {
                setLockMessage(err.response.data?.errors?.message)
            }
        }
    }

    const handleSelectMoreTickets = (qty: any) => {
        //console.log('SelectedTickets before :',   selectedTickets);
        // setSelectedTickets([])
        //  console.log('SelectedTickets after:',   selectedTickets);

        handleSearch(0, qty)
    }

    const handleCheckTicket = (e: any, index: number) => {

        const isChecked = e.target.checked
        //Hconsole.log('ClickSingle isChecked:', isChecked);

        const item = e.target.value
        console.log('cart item1:', item);

        const isItemInCart = cart.some(cartItem => cartItem.id === parseInt(item));

        if (isItemInCart) {
            removeFromCartByTicketId(parseInt(item));
        }

        const tempT = [...ticketResult]

        //Hconsole.log('ClickSingle tempT:', tempT);

        tempT[index] = {...tempT[index], blocked: true}

        //Hconsole.log('ClickSingle tempT[index]:', tempT[index]);

        //Hconsole.log('ClickSingle before ticketResult:', ticketResult);
        setTicketResult(tempT)
        console.log('ClickSingle after ticketResult:', ticketResult)

        setLockMessage('')

        if (isChecked && !selectedTickets.includes(item)) {
            ticketLock(item, 'lock', 0)

            //setSelectedTickets([...selectedTickets, item]);
        } else if (!isChecked) {
            ticketLock(item, 'unlock', 0)

            //setSelectedTickets(selectedTickets.filter(tkt => tkt!= item));
        }

        return false
    }

    const addSelectedTicketsToCart = (selectedTckts) => {
        setOpenAlert(true)
        setSeverity('success')
        setSuccessMsg(selectedTckts.length + ' Tickets Added to the Cart')

        setCart((prevCart: any) => {
            if (tabId === null) {
                setTabId(uniqueId)
            }

            let newCart = [...prevCart];

            if (prevCart.length === 0) {
                const currentTime = new Date();
                const timeFiveMinutesLater = new Date(currentTime.getTime() + 5 * 60000);
                setCartEndTime(new Date(timeFiveMinutesLater));
            }

            const currentLotteryTickets = newCart.filter(
                (cartItem) =>
                    cartItem.lottery_id === lottery.lottery_id &&
                    !selectedTckts.includes(cartItem.id) &&
                    cartItem.source === 'search_and_buy'
            );

            selectedTckts.forEach((ticketId: any) => {
                const existingItem = newCart.find(
                    (cartItem) =>
                        cartItem.id === ticketId &&
                        cartItem.lottery_id === lottery.lottery_id &&
                        cartItem.source === 'search_and_buy'
                );
                if (!existingItem) {
                    newCart.push({
                        id: ticketId,
                        quantity: 1,
                        lottery_id: lottery.lottery_id,
                        source: tab === 2 ? 'search_and_buy' : undefined,
                    });
                }
            });


            newCart = newCart.filter(
                (cartItem) =>
                    cartItem.lottery_id !== lottery.lottery_id ||
                    selectedTckts.includes(cartItem.id) ||
                    cartItem.source === 'quick_buy'
            );

            newCart = [...newCart, ...currentLotteryTickets];

            return newCart;
        });
    };


    const handleTopupFormErrors = (error: string) => {
        setBlocking(false)

        setErrMsg(error)
    }

    const handleSearchX = (page_no: number, qty) => {
        setPageNo(page_no)
        setQuantity(qty)
    }

    const handleReset = () => {
        setTicketMessage('')
        setSelectedOption(null)
        setSearchArray([])
        setTicketResult([])
    }

    const handleSearch = async (page_no: number, qty) => {
        setErrMsg('')
        if (page_no == 0) {
            setTicketPageCount(0)
        }

        try {
            setResultBlocking(true)

            setOpenFavMsg(false)

            const response = await axiosPrivate.post(
                'ticket/search/web' + (page_no > 0 ? '?page=' + (page_no + 1) : ''),

                {
                    ...searchArray,

                    lottery_id: lottery.lottery_id,

                    draw_id: drawNo,

                    page_count: qty,
                }
            )

            const result = response?.data

            if (result?.next_page_url != null) {
                setMortTicketAvail(true)

                setTicketPageCount(ticketPageCount + 1)
            } else setMortTicketAvail(false)

            if (result?.total > 0) {
                const tempdata: any = []

                const selectedata: any = []

                let j = 0
                let k = 0
                for (var i in result.data) {
                    const index = selectedTickets.indexOf(result.data[i]['ticket_id'])

                    if (index >= 0) continue
                    tempdata[k] = result.data[i]
                    console.log(
                        'tempdata[k] = result.data[i]',
                        (tempdata[k] = result.data[i])
                    )
                    tempdata[k]['checked'] = false

                    tempdata[k]['blocked'] = false
                    console.log(' tempdata[k][blocked]', tempdata[k]['blocked'])
                    tempdata[k]['availability_status'] = 'true'
                    console.log(
                        'selectedTickets.indexOf(result.data[i][ticket_id]',
                        selectedTickets.indexOf(result.data[i]['ticket_id'])
                    )
                    k++

                    // console.log('          k++',            k++)
                }

                for (var i in ticketResult) {
                    if (
                        ticketResult[i]['checked'] == true &&
                        ticketResult[i]['availability_status'] == 'true'
                    ) {
                        selectedata[j] = ticketResult[i]
                        console.log(
                            ' selectedata[j] = ticketResult[i]',
                            (selectedata[j] = ticketResult[i])
                        )
                        j++

                        console.log('      j++', j++)
                    }
                }
                // //Hconsole.log(pageNo)
                //Hconsole.log('length6', selectedata.length)

                if (selectedata.length > 0)
                    setTicketResult(selectedata.concat(tempdata))
                else if (pageNo > 0) {
                    setTicketResult(ticketResult.concat(tempdata))
                    setPageNo(0)
                } else setTicketResult(tempdata)

                scrollToTicketNumber()
            } else setTicketMessage('No Tickets Available.')

            setResultBlocking(false)
        } catch (err) {
            setResultBlocking(false)

            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors)
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
        0
    }

    const getCeylincoActiveStatus = async (e: any) => {
        try {
            setBlocking(true)
            // setOpen(false)
            const response = await axiosPrivate.get(
                '/user/ceylico/active/status/' + e
            )

            setBlocking(false)
            // console.log("======================")
            // console.log(response.data?.message)

            setCeylincoOnoActiveStatus(response.data?.message?.ono_active_status)
            setCeylincoOnoActiveStatusC(response.data?.message?.ono_active_status)
            if (
                response.data?.message?.ono_active_status != null &&
                response.data?.message?.ono_active_status != 0
            ) {
                // if (response.data?.message?.ono_free_offer == 0) {
                setCustomerPremium(response.data?.message?.premium_percentage_cx)
                setCustomerPremiumC(response.data?.message?.premium_percentage_cx)
                setCcwpPremium(response.data?.message?.premium_percentage_ccwp)
                setCcwpPremiumC(response.data?.message?.premium_percentage_ccwp)
                // } else if (response.data?.message?.ono_free_offer == 1) {
                // }
            } else if (
                response.data?.message?.ono_active_status == null ||
                response.data?.message?.ono_active_status == 0
            ) {
                // setShowPremiumSection(false)
                // console.log("ono_status else", response.data?.message?.ono_active_status);
            }
            // setCeylincoOnoActiveStatus(isChecked)
        } catch (err) {
            setBlocking(false)
        }
    }

    console.log('ceylincoOnoActiveStatus', customerPremium, ccwpPremium)

    const handleCloseQuickBuy = () => {
        setOpen(false)
        handleClose()
        setOpenAlert(false)
    }

    // Add Commnet By LashenDEV
    // const handleChange = (element, index) => {
    //     if (!/^[0-9]$/.test(element.value)) return false;
    //     setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    //
    //     if (element.nextSibling) {
    //         element.nextSibling.focus();
    //     }
    // };
    //
    // const handleKeyDown = (e, index) => {
    //     if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'Tab' && !e.metaKey) {
    //         e.preventDefault();
    //     }
    //
    //     if (e.key === 'Delete' || e.key === 'Backspace') {
    //         const newOtp = [...otp];
    //         newOtp[index] = "";
    //         setOtp(newOtp);
    //         if (index > 0) {
    //             inputs.current[index - 1].focus();
    //         }
    //     }
    // };
    //
    // const handlePaste = (e) => {
    //     e.preventDefault();
    //     const paste = e.clipboardData.getData('text').split('');
    //     if (paste.length === otp.length && /^[0-9]{4}$/.test(paste.join(''))) {
    //         setOtp(paste);
    //         inputs.current[otp.length - 1].focus();
    //     }
    // };
    //
    // const handleFocus = (e) => {
    //     e.target.select();
    // };
    //
    // useEffect(() => {
    //     if (inputs.current[0]) {
    //         inputs.current[0].focus();
    //     }
    // }, []);

    return (
        <>
            {!token && quickBuyLoginOpen && (
                <>
                    <div className="container">
                        <div className="row row-clr register-pop-up">
                            <div className="row heading"></div>

                            <LoginFrom
                                handleClose={handleClose}
                                open={!token && quickBuyLoginOpen}
                                title={title}
                                setOpen={setOpen}
                            />
                            {/*<>*/}
                            {/*    <div*/}
                            {/*        className="otp-container">*/}
                            {/*        <div className="otp-container-max-width">*/}
                            {/*            <div className="otp-inner-wrapper">*/}
                            {/*                <div*/}
                            {/*                    className="otp-inner-container">*/}
                            {/*                    <img*/}
                            {/*                        data-dismiss="modal"*/}
                            {/*                        src={require('../../assets/images/buy-form/back-button.png')}*/}
                            {/*                        onClick={handleClose}*/}
                            {/*                        className="img-responsive close-button otp-close-icon"*/}
                            {/*                        alt="Image"*/}
                            {/*                    />*/}
                            {/*                    <header className="otp-header-wrapper">*/}
                            {/*                        {!sentOtp ? (*/}
                            {/*                                <>*/}
                            {/*                                    <h1 className="otp-heading">{title}</h1>*/}

                            {/*                                    <p className="otp-sub-heading">{t('Enter Mobile No')}</p>*/}
                            {/*                                </>)*/}
                            {/*                            :*/}
                            {/*                            (*/}
                            {/*                                <>*/}
                            {/*                                    <h1 className="otp-heading">{t('Enter verfication code')}</h1>*/}
                            {/*                                    <p className="otp-sub-heading"> {t('Verification Code Sent', {mobile: mobile})}</p>*/}
                            {/*                                </>*/}
                            {/*                            )*/}
                            {/*                        }*/}
                            {/*                    </header>*/}
                            {/*                    {!sentOtp ?*/}
                            {/*                        <form onSubmit={handleSubmitStep1}>*/}
                            {/*                            <div className="row row-clr">*/}
                            {/*                                <h5> {t('Mobile Number')}</h5>*/}
                            {/*                            </div>*/}

                            {/*                            <div className="row row-clr">*/}
                            {/*                                <input*/}
                            {/*                                    type="text"*/}
                            {/*                                    name="mobile"*/}
                            {/*                                    id="mobile"*/}
                            {/*                                    autoComplete="off"*/}
                            {/*                                    className="form-control"*/}
                            {/*                                    onChange={(e) => setMobile(e.target.value)}*/}
                            {/*                                    defaultValue=""*/}
                            {/*                                    value={mobile}*/}
                            {/*                                    autoFocus*/}
                            {/*                                />*/}

                            {/*                                {errMsg && (*/}
                            {/*                                    <span*/}
                            {/*                                        ref={errRef}*/}
                            {/*                                        className={errMsg ? 'has-error' : 'offscreen'}*/}
                            {/*                                        aria-live="assertive"*/}
                            {/*                                    >{errMsg}</span>*/}
                            {/*                                )}*/}
                            {/*                            </div>*/}

                            {/*                            <div className="row row-clr button-wrapper">*/}
                            {/*                                <BlockUi tag="div" blocking={blocking}>*/}
                            {/*                                    <button type="submit" className="button-type-7">*/}
                            {/*                                        {t('Send verification code')}*/}
                            {/*                                    </button>*/}
                            {/*                                </BlockUi>*/}
                            {/*                            </div>*/}
                            {/*                        </form>*/}
                            {/*                        :*/}
                            {/*                        <>*/}
                            {/*                            <form id="otp-form" onSubmit={handleSubmitStep2}*/}
                            {/*                                  data-aos="fade-left">*/}
                            {/*                                <div className="otp-form-inner-wrapper">*/}
                            {/*                                    {otp.map((data, index) => (*/}
                            {/*                                        <input*/}
                            {/*                                            key={index}*/}
                            {/*                                            type="text"*/}
                            {/*                                            className="otp-input"*/}
                            {/*                                            maxLength="1"*/}
                            {/*                                            value={data}*/}
                            {/*                                            onChange={e => handleChange(e.target, index)}*/}
                            {/*                                            onKeyDown={e => handleKeyDown(e, index)}*/}
                            {/*                                            onFocus={handleFocus}*/}
                            {/*                                            ref={el => (inputs.current[index] = el)}*/}
                            {/*                                            onPaste={handlePaste}*/}
                            {/*                                        />*/}
                            {/*                                    ))}*/}
                            {/*                                </div>*/}
                            {/*                                {errMsg && (*/}
                            {/*                                    <span*/}
                            {/*                                        ref={errRef}*/}
                            {/*                                        className={errMsg ? 'has-error' : 'offscreen'}*/}
                            {/*                                        aria-live="assertive">*/}
                            {/*                                      {errMsg}*/}
                            {/*                                    </span>*/}
                            {/*                                )}*/}
                            {/*                                <BlockUi tag="div" blocking={blocking}>*/}
                            {/*                                    <button type="submit" className="button-type-7">*/}
                            {/*                                        {' '}*/}
                            {/*                                        {t('Verify')}*/}
                            {/*                                    </button>*/}
                            {/*                                </BlockUi>*/}
                            {/*                            </form>*/}
                            {/*                            <div className="otp-resend-container">*/}
                            {/*                                Didn't receive code?{' '}*/}
                            {/*                                <a className="resend-link" href="#0">*/}
                            {/*                                    Resend*/}
                            {/*                                </a>*/}
                            {/*                            </div>*/}
                            {/*                            <div className="right" style={{textAlign: 'center'}}>*/}
                            {/*                                {timer > 0 ? (*/}
                            {/*                                    <h5*/}
                            {/*                                        dangerouslySetInnerHTML={{*/}
                            {/*                                            __html: t('Resend verification code', {*/}
                            {/*                                                second: timer,*/}
                            {/*                                            }),*/}
                            {/*                                        }}*/}
                            {/*                                    ></h5>*/}
                            {/*                                ) : (*/}
                            {/*                                    <>*/}
                            {/*                                        <a className="">{t('Go back')}</a>&nbsp;&nbsp;*/}
                            {/*                                        <a className="">{t('Resend')}</a>*/}
                            {/*                                    </>*/}
                            {/*                                )}*/}
                            {/*                            </div>*/}
                            {/*                        </>*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</>*/}
                        </div>
                    </div>
                </>
            )}

            {open && (
                <div className="container" style={{marginTop: "15px"}}>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        open={openAlert}
                        autoHideDuration={3000}
                        onClose={handleAlertClose}
                        key={{vertical: 'top', horizontal: 'center'}}
                    >
                        <Alert
                            iconMapping={{success: <CheckCircleOutlineIcon fontSize="inherit"/>}}
                            onClose={handleAlertClose}
                            severity={severity}
                            sx={{width: '100%'}}
                        >
                            {successMsg}
                        </Alert>
                    </Snackbar>
                    <IsPurchasedConversionTracking/>

                    {token && !perchaseSuccess && (
                        <div className="row popup-section">
                            <form onSubmit={handleSubmitBuyTicket}>
                                <div className="row heading">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <a onClick={() => handleCloseQuickBuy()}>
                                            <img
                                                data-dismiss="modal"
                                                src={require('../../assets/images/buy-form/back-button.png')}
                                                className="img-responsive close-button"
                                                alt="Image"
                                            />
                                        </a>
                                        <div
                                            className="row row-clr heading-row lottery-heading"
                                            style={{display: 'flex', justifyContent: 'center'}}
                                        >
                                            <img
                                                src={`${lottery.image_file_path}`}
                                                className="img-responsive"
                                                style={{height: '45px'}}
                                                alt="Image"
                                            />

                                            <h4>
                                                {t('Buy')} <span>{lottery.name}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                {accountBalanceAlert && (
                                    <div
                                        className="row row-clr error-wrapper blue"
                                        data-aos="fade-down"
                                    >
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 img-wrapper">
                                            <img
                                                src={require('../../assets/images/popup/1.svg')}
                                                className="img-responsive"
                                                alt="Image"
                                            />
                                        </div>

                                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 content-wrapper">
                                            <p>
                                                {t('Account Balance Text', {
                                                    account_balance: accountBalance.toFixed(2),
                                                })}

                                                <a href="#topup-wrapper" className="topup-click">
                                                    {t('Topup now')}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <div className="row row-clr tab-wrapper">
                                    <div className="tab-teaser">
                                        <div className="tab-menu">
                                            <ul>
                                                <li>
                                                    <a
                                                        href="#"
                                                        className={tab == 1 ? 'active' : ''}
                                                        data-rel="tab-1"
                                                        onClick={() => handleTabSelect(1)}
                                                    >
                                                        {t('Quick pick')}

                                                        <span>{t('System generated lucky numbers')}</span>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a
                                                        href="#"
                                                        data-rel="tab-2"
                                                        className={tab == 2 ? 'active' : ''}
                                                        onClick={() => handleTabSelect(2)}
                                                    >
                                                        {t('Pick my numbers')}

                                                        <span>
                                                    {' '}
                                                            {t('Search and pick your lucky numbers')}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="tab-main-box">
                                            <div
                                                className="tab-box "
                                                id="tab-1"
                                                style={{display: tab == 1 ? 'block' : 'none'}}
                                            >
                                                <div className="row row-clr">
                                                    <h5>{t('Number of tickets')}</h5>
                                                </div>

                                                <BlockUi tag="div" blocking={blocking}>
                                                    <div className="row row-clr number-wrapper">
                                                        <div
                                                            className="col-xs-10 col-sm-10 col-md-10 col-lg-10 content-wrapper">
                                                            <ul className="clerfix ticket-count">
                                                                <li
                                                                    onClick={() => handleSelectTicketCount(1)}
                                                                    className={
                                                                        ticketCount == 1 ? 'supper-class' : ''
                                                                    }
                                                                >
                                                                    1
                                                                </li>

                                                                <li
                                                                    onClick={() => handleSelectTicketCount(2)}
                                                                    className={
                                                                        ticketCount == 2 ? 'supper-class' : ''
                                                                    }
                                                                >
                                                                    2
                                                                </li>

                                                                <li
                                                                    onClick={() => handleSelectTicketCount(5)}
                                                                    className={
                                                                        ticketCount == 5
                                                                            ? 'supper-number supper-class'
                                                                            : 'supper-number'
                                                                    }
                                                                >
                                                                    5
                                                                </li>

                                                                <li
                                                                    onClick={() => handleSelectTicketCount(10)}
                                                                    className={
                                                                        ticketCount == 10 ? 'supper-class' : ''
                                                                    }
                                                                >
                                                                    10
                                                                </li>

                                                                <li
                                                                    onClick={() => handleSelectTicketCount(20)}
                                                                    className={
                                                                        ticketCount != 1 &&
                                                                        ticketCount != 2 &&
                                                                        ticketCount != 5 &&
                                                                        ticketCount != 10
                                                                            ? 'supper-class other'
                                                                            : 'other'
                                                                    }
                                                                >
                                                                    Other
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div
                                                            className="col-xs-2 col-sm-2 col-md-2 col-lg-2 img-wrapper spmeter-wrapper">
                                                            <ReactSpeedometer
                                                                width={140}
                                                                height={96}
                                                                fluidWidth={false}
                                                                // forceRender={true}

                                                                minValue={0} //<---here
                                                                maxValue={20} //<---here
                                                                needleHeightRatio={0.6}
                                                                value={ticketMeter}
                                                                currentValueText="Winning Chance"
                                                                paddingHorizontal={0}
                                                                paddingVertical={0}
                                                                className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                                                                customSegmentStops={[0, 1, 2, 5, 10, 15, 20]}
                                                                segmentColors={[
                                                                    '#FF0000',

                                                                    '#FFA500',

                                                                    '#FFFF00',

                                                                    '#00FF00',

                                                                    '#32CD32',

                                                                    '#008000',

                                                                    '#033E3E',
                                                                ]}
                                                                customSegmentLabels={[
                                                                    {
                                                                        // text: '1',

                                                                        position: 'INSIDE',

                                                                        color: '#555',
                                                                    },

                                                                    {
                                                                        // text: '2',

                                                                        position: 'INSIDE',

                                                                        color: '#555',
                                                                    },

                                                                    {
                                                                        // text: '5',

                                                                        position: 'INSIDE',

                                                                        color: '#555',

                                                                        // fontSize: '19px',
                                                                    },

                                                                    {
                                                                        // text: '10',

                                                                        position: 'INSIDE',

                                                                        color: '#555',
                                                                    },

                                                                    {
                                                                        // text: '10+',

                                                                        position: 'INSIDE',

                                                                        color: '#555',
                                                                    },

                                                                    {
                                                                        // text: '10+',

                                                                        position: 'INSIDE',

                                                                        color: '#555',
                                                                    },
                                                                ]}
                                                                ringWidth={15}
                                                                needleTransitionDuration={3333}
                                                                needleTransition="easeElastic"
                                                                needleColor={'#4169E1'}
                                                                textColor="#5f2eea"
                                                            />
                                                        </div>
                                                    </div>
                                                </BlockUi>

                                                <div className="row detail-row">
                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        <label>{t('Draw')}</label>

                                                        <select
                                                            name="drawno"
                                                            id="drawno"
                                                            className="form-control"
                                                            onChange={(e) => setDrawNo(e.target.value)}
                                                        >
                                                            {draws &&
                                                                draws.map((draw: any, index: number) => (
                                                                    <option key={draw.id} value={draw.id}>
                                                                        {draw.drawdate} ({draw.drawnumber})
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>

                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        <label>{t('Mobile Number')}</label>

                                                        <select
                                                            name="mobile_no"
                                                            id="mobile_no"
                                                            className="form-control"
                                                            onChange={(e) => setUserMobile(e.target.value)}
                                                        >
                                                            {userMobiles.length > 0 &&
                                                                userMobiles.map((itemx: any) => (
                                                                    <option value={itemx}>{itemx}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                {tab == 1 && (
                                                    <div className="row detail-row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                            <label>{t('Number of Tickets')}</label>

                                                            <input
                                                                type="number"
                                                                name="other"
                                                                id="other"
                                                                min="1"
                                                                autoComplete="off"
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                    handleSelectTicketCount(
                                                                        parseInt(e.target.value)
                                                                    )
                                                                }
                                                                defaultValue=""
                                                                value={ticketCount}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div
                                                className="tab-box"
                                                id="tab-2"
                                                style={{display: tab == 2 ? 'block' : 'none'}}
                                            >
                                                <div className="row detail-row">
                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        <label>{t('Draw')}</label>

                                                        <select
                                                            name="drawnox"
                                                            id="drawnox"
                                                            className="form-control"
                                                            onChange={(e) => setDrawNo(e.target.value)}
                                                        >
                                                            {draws &&
                                                                draws.map((draw: any, index: number) => (
                                                                    <option key={draw.id} value={draw.id}>
                                                                        {draw.drawdate} ({draw.drawnumber})
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>

                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        <label>{t('Mobile Number')}</label>

                                                        <select
                                                            name="mobilex_no"
                                                            id="mobilex_no"
                                                            className="form-control"
                                                            onChange={(e) => setUserMobile(e.target.value)}
                                                        >
                                                            {userMobiles.length > 0 &&
                                                                userMobiles.map((mobile: any) => (
                                                                    <option value={mobile}>{mobile}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row lucky_number_wrapper">
                                                    <div
                                                        className="col-xs-12 col-sm-8 col-md-8 col-lg-8 content-wrapper">
                                                        <div className="row">
                                                            <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                                                <h4>Search my lucky number</h4>
                                                            </div>

                                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                                {/* <div className="pull-right fav-switch">
                                <Switch
                                  color="secondary"
                                  value={save}
                                  onChange={(e) => handleCardSaving(e)}
                                />
                              </div> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                          <div className="row">
                            <p>Save favourites</p>
                          </div>
                        </div> */}

                                                    {/* <Collapse in={openFavMsg}>
                          <Alert
                            severity={`${favouriteSeverity}`}
                            onClose={() => setOpenFavMsg(false)}
                          >
                            {msgFavourite}
                          </Alert>
                        </Collapse> */}
                                                </div>

                                                <div
                                                    className={`row search-number-wrapper ${
                                                        lotteryFormat.length > 5
                                                            ? 'ten-numbers-search-wrapper'
                                                            : ''
                                                    }`}
                                                >
                                                    <BlockUi tag="div" blocking={formatBlocking}>
                                                        {lotteryFormat &&
                                                            lotteryFormat.length > 0 &&
                                                            lotteryFormat.map(
                                                                (format: any, index: number) => (
                                                                    <div
                                                                        key={index}
                                                                        className={
                                                                            format.substr(0, 5) == 'lagna'
                                                                                ? 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper lagna-select'
                                                                                : 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper'
                                                                        }
                                                                    >
                                                                        {format.substr(0, 5) == 'lagna' && (
                                                                            <>
                                                                                <ZodiacDropdown
                                                                                    setSearchArray={setSearchArray}
                                                                                    searchArray={searchArray}
                                                                                    selectedOption={selectedOption}
                                                                                    setSelectedOption={setSelectedOption}
                                                                                />
                                                                            </>
                                                                        )}

                                                                        {format.substr(0, 5) != 'lagna' && (
                                                                            <input
                                                                                type={
                                                                                    format.substr(0, 1) == 'n'
                                                                                        ? 'number'
                                                                                        : 'text'
                                                                                }
                                                                                min={
                                                                                    format.substr(0, 1) == 'n' ? 0 : ''
                                                                                }
                                                                                placeholder={format}
                                                                                name="formats[]"
                                                                                id={`${'format' + index}`}
                                                                                className="form-control"
                                                                                value={searchArray[format]}
                                                                                onChange={(e) =>
                                                                                    handleSearchArray(
                                                                                        format,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                    </BlockUi>

                                                    <div
                                                        className="col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper  button-wrapper">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default"
                                                            onClick={() => handleSearch(0, ticketQty)}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>

                                                    <div
                                                        className="col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper  button-wrapper">
                                                        <button
                                                            type="reset"
                                                            className="reset-btn"
                                                            onClick={() => handleReset()}
                                                        >
                                                            <RestartAltIcon/> Reset
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="row search-number-wrapper">
                                                    <div
                                                        className="col-xs-12 col-sm-6 col-md-6 col-lg-6 inner-wrapper button-wrapper pull-left">
                                                        <select
                                                            name="more_tickets"
                                                            id="more_tickets"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                handleSelectMoreTickets(e.target.value)
                                                            }
                                                        >
                                                            <option value="">
                                                                {t('Select more tickets')}
                                                            </option>

                                                            <option value="50">
                                                                {t('Select 50 tickets')}
                                                            </option>

                                                            <option value="100">
                                                                {t('Select 100 tickets')}
                                                            </option>

                                                            <option value="150">
                                                                {t('Select 150 tickets')}
                                                            </option>

                                                            <option value="200">
                                                                {t('Select 200 tickets')}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {ticketMessage.length > 0 && (
                                                    <div className="row">
                            <span className="has-error">
                              {t(ticketMessage)}
                            </span>
                                                    </div>
                                                )}

                                                <BlockUi tag="div" blocking={resultBlocking}>
                                                    <div className="result-wrapper">
                                                        {ticketResult.map((ticket, index) => (
                                                            <React.Fragment key={index}>
                                                                {ticket && (
                                                                    <BlockUi
                                                                        tag="div"
                                                                        blocking={ticket['blocked']}
                                                                    >
                                                                        <div
                                                                            key={index}
                                                                            className={`row result-number-wrapper ${
                                                                                lotteryFormat?.length > 5
                                                                                    ? 'ten-numbers-result-wrapper'
                                                                                    : ''
                                                                            }
     
     ${ticket['checked'] ? ' checked' : ''}`}
                                                                        >
                                                                            <div
                                                                                className="col-xs-4 col-sm-4 col-md-2 col-lg-2 action-wrapper">
                                                                                <div className="checkbox-wrapper">
                                                                                    {/* <label className="container">
         <input
           type="checkbox"
           id={`tkt-${ticket['ticket_id']}`}
           name="tkt[]"
           value={ticket['ticket_id']}
           onChange={(e) => handleCheckTicket(e, index)}
           checked={ticket['blocked'] || ticket['availability_status'] === 'false' || ticket['checked']}

         />

         <span className="checkmark"></span>
       </label> */}

                                                                                    {ticket['availability_status'] ===
                                                                                    'false' ? (
                                                                                        <div>
                                                                                            <label
                                                                                                className="container">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`tkt-${ticket['ticket_id']}`}
                                                                                                    name="tkt[]"
                                                                                                    value={ticket['ticket_id']}
                                                                                                    onChange={(e) =>
                                                                                                        handleCheckTicket(e, index)
                                                                                                    }
                                                                                                    // checked={ticket['checked']}
                                                                                                    disabled={true}
                                                                                                />
                                                                                                <span
                                                                                                    className="checkmark"></span>
                                                                                                {/* <span style={{ fontSize: "11px", color: "red", display: "flex", backgroundColor: "pink", flexDirection: "row" }}>This ticket is not available.</span> */}
                                                                                            </label>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <label className="container">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id={`tkt-${ticket['ticket_id']}`}
                                                                                                name="tkt[]"
                                                                                                value={ticket['ticket_id']}
                                                                                                onChange={(e) =>
                                                                                                    handleCheckTicket(e, index)
                                                                                                }
                                                                                                checked={cart
                                                                                                    .filter(
                                                                                                        (cartItem) =>
                                                                                                            cartItem.lottery_id ===
                                                                                                            lottery.lottery_id
                                                                                                    )
                                                                                                    .map((ticket) => ticket.id)
                                                                                                    .includes(
                                                                                                        ticket['ticket_id']
                                                                                                    )}
                                                                                                // disabled={ticket['availability_status'] === 'false'}
                                                                                            />
                                                                                            <span
                                                                                                className="checkmark"></span>
                                                                                        </label>
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            {/* {formatArray.map((cell, idx) => (
     <div
       key={idx}
       className={
         cell.substr(0, 5) == 'lagna'
           ? 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper lagna-wrapper'
           : 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper'
       }
     >
       <div className="box-wrapper first-box" >
         {cell.substr(0, 5) == 'lagna'
           ? ticket[cell].substr(0, 3)
           : ticket[cell]}
       </div>
     </div>
   ))} */}
                                                                            {ticket['availability_status'] ===
                                                                            'false' ? (
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: '14px',
                                                                                        color: 'red',
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        padding: '12px',
                                                                                        width: '410px',
                                                                                    }}
                                                                                >
                                          This ticket is not available.
                                        </span>
                                                                            ) : (
                                                                                <>
                                                                                    {formatArray.map((cell, idx) => (
                                                                                        <div
                                                                                            key={idx}
                                                                                            className={
                                                                                                cell.substr(0, 5) === 'lagna'
                                                                                                    ? 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper lagna-wrapper'
                                                                                                    : 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper'
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                className="box-wrapper first-box">
                                                                                                {cell.substr(0, 5) ===
                                                                                                'lagna' ? (
                                                                                                    <img
                                                                                                        src={
                                                                                                            lagna.find(
                                                                                                                (item) =>
                                                                                                                    item.value ===
                                                                                                                    ticket[cell]
                                                                                                            )?.image
                                                                                                        }
                                                                                                        alt={
                                                                                                            lagna.find(
                                                                                                                (item) =>
                                                                                                                    item.value ===
                                                                                                                    ticket[cell]
                                                                                                            )?.image
                                                                                                        }
                                                                                                        className="img-responsive zodiac-sign"
                                                                                                    />
                                                                                                ) : (
                                                                                                    ticket[cell]
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </BlockUi>
                                                                )}
                                                            </React.Fragment>

                                                            // <BlockUi tag="div" blocking={ticket['blocked']}>

                                                            //   <div
                                                            //     key={index}
                                                            //     className={`row result-number-wrapper ${lotteryFormat?.length > 5
                                                            //       ? 'ten-numbers-result-wrapper'
                                                            //       : ''
                                                            //       }

                                                            //       ${ticket['checked'] ? ' checked' : ''}`}
                                                            //   >
                                                            //     <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2 action-wrapper">
                                                            //       <div className="checkbox-wrapper">

                                                            //         {/* <label className="container">
                                                            //           <input
                                                            //             type="checkbox"
                                                            //             id={`tkt-${ticket['ticket_id']}`}
                                                            //             name="tkt[]"
                                                            //             value={ticket['ticket_id']}
                                                            //             onChange={(e) => handleCheckTicket(e, index)}
                                                            //             checked={ticket['blocked'] || ticket['availability_status'] === 'false' || ticket['checked']}

                                                            //           />

                                                            //           <span className="checkmark"></span>
                                                            //         </label> */}

                                                            //         {ticket['availability_status'] === 'false' ? (
                                                            //           <div>
                                                            //             <label className="container">
                                                            //               <input
                                                            //                 type="checkbox"
                                                            //                 id={`tkt-${ticket['ticket_id']}`}
                                                            //                 name="tkt[]"
                                                            //                 value={ticket['ticket_id']}
                                                            //                 onChange={(e) => handleCheckTicket(e, index)}
                                                            //                 // checked={ticket['checked']}
                                                            //                 disabled={true}
                                                            //               />
                                                            //               <span className="checkmark"></span>
                                                            //               {/* <span style={{ fontSize: "11px", color: "red", display: "flex", backgroundColor: "pink", flexDirection: "row" }}>This ticket is not available.</span> */}
                                                            //             </label>

                                                            //           </div>

                                                            //         ) : (
                                                            //           <label className="container">
                                                            //             <input
                                                            //               type="checkbox"
                                                            //               id={`tkt-${ticket['ticket_id']}`}
                                                            //               name="tkt[]"
                                                            //               value={ticket['ticket_id']}
                                                            //               onChange={(e) => handleCheckTicket(e, index)}
                                                            //               checked={ticket['checked']}
                                                            //             // disabled={ticket['availability_status'] === 'false'}
                                                            //             />
                                                            //             <span className="checkmark"></span>
                                                            //           </label>
                                                            //         )}
                                                            //       </div>

                                                            //     </div>

                                                            //     {/* {formatArray.map((cell, idx) => (
                                                            //       <div
                                                            //         key={idx}
                                                            //         className={
                                                            //           cell.substr(0, 5) == 'lagna'
                                                            //             ? 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper lagna-wrapper'
                                                            //             : 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper'
                                                            //         }
                                                            //       >
                                                            //         <div className="box-wrapper first-box" >
                                                            //           {cell.substr(0, 5) == 'lagna'
                                                            //             ? ticket[cell].substr(0, 3)
                                                            //             : ticket[cell]}
                                                            //         </div>
                                                            //       </div>
                                                            //     ))} */}
                                                            //     {ticket['availability_status'] === 'false' ? (
                                                            //       <span style={{ fontSize: "14px", color: "red", display: "flex", flexDirection: "row", padding: "12px", width: '410px' }}>
                                                            //         This ticket is not available.
                                                            //       </span>
                                                            //     ) : (
                                                            //       <>
                                                            //         {formatArray.map((cell, idx) => (
                                                            //           <div
                                                            //             key={idx}
                                                            //             className={
                                                            //               cell.substr(0, 5) === 'lagna'
                                                            //                 ? 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper lagna-wrapper'
                                                            //                 : 'col-xs-4 col-sm-4 col-md-2 col-lg-2 inner-wrapper'
                                                            //             }
                                                            //           >
                                                            //             <div className="box-wrapper first-box">
                                                            //               {cell.substr(0, 5) === 'lagna'
                                                            //                 ? ticket[cell].substr(0, 3)
                                                            //                 : ticket[cell]}
                                                            //             </div>
                                                            //           </div>
                                                            //         ))}
                                                            //       </>
                                                            //     )}

                                                            //   </div>
                                                            // </BlockUi>
                                                        ))}

                                                        <div ref={resultEndRef}/>
                                                    </div>
                                                </BlockUi>

                                                {lockMessage.length > 0 && (
                                                    <div className="row">
                                                        <span className="has-error">{t(lockMessage)}</span>
                                                    </div>
                                                )}

                                                <div className="row show-more-wrapper">
                                                    <div
                                                        className="col-xs-12 col-sm-6 col-md-6 col-lg-6 checkbox-wrapper">
                                                        {ticketResult.length > 0 && (
                                                            <label className="container">
                                                                {t('Select all tickets')}

                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => handleCheckAll(e)}
                                                                />

                                                                <span className="checkmark"></span>
                                                            </label>
                                                        )}
                                                    </div>

                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        {mortTicketAvail &&
                                                            ticketResult.length < MAX_TICKET_COUNT && (
                                                                <a
                                                                    onClick={() =>
                                                                        handleSearchX(ticketPageCount, ticketQty)
                                                                    }
                                                                >
                                                                    {t('Show more results of')} {MAX_TICKET_COUNT}
                                                                    <img
                                                                        src={require('../../assets/images/popup/Forward.png')}
                                                                        className="img-responsive"
                                                                        alt="Image"
                                                                    />
                                                                </a>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-clr total-price">
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 left">
                                        <div className="row">
                                            <p>{t('Total charge')}</p>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 right">
                                        <div className="row">
                                            <p>
                                                {tab == 1 && (
                                                    <>
                                                        <NumberFormat
                                                            value={
                                                                paymentOption == 1
                                                                    ? (lottery.ticket_price + CONVENIENT_FEE) *
                                                                    ticketCount
                                                                    : parseFloat(
                                                                        lottery.ticket_price * ticketCount +
                                                                        lottery.ticket_price *
                                                                        ticketCount *
                                                                        (ceylincoOnoActiveStatus == 1 &&
                                                                        ceylincoOnoActiveStatus != null
                                                                            ? customerPremium / 100
                                                                            : 0)
                                                                    ).toFixed(2)
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix="Rs. "
                                                            // suffix="/="
                                                        />

                                                        {paymentOption == 1 && ticketCount > 0
                                                            ? ' + tax'
                                                            : ''}
                                                    </>
                                                )}

                                                {tab != 1 && (
                                                    <>
                                                        <NumberFormat
                                                            value={
                                                                paymentOption == 1
                                                                    ? (lottery.ticket_price + CONVENIENT_FEE) *
                                                                    selectedTickets.length
                                                                    : parseFloat(
                                                                        lottery.ticket_price *
                                                                        selectedTickets.length +
                                                                        lottery.ticket_price *
                                                                        selectedTickets.length *
                                                                        (ceylincoOnoActiveStatus == 1 &&
                                                                        ceylincoOnoActiveStatus != null
                                                                            ? customerPremium / 100
                                                                            : 0)
                                                                    ).toFixed(2)
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            // suffix="/="
                                                            prefix="Rs. "
                                                        />

                                                        {paymentOption == 1 && selectedTickets.length > 0
                                                            ? ' + tax'
                                                            : ''}
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {user && (user.nicno == null || user.nicno?.length == 0) && (
                                    <div className="row detail-row">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" id="nic-error">
                                            <label>
                                                To get Winnings/දිනුම්/வெற்றிப்பணம் please give NIC
                                            </label>

                                            <input
                                                type="text"
                                                name="nicno"
                                                id="nicno"
                                                autoComplete="off"
                                                className="form-control"
                                                pattern="^([0-9]{9,12})(V|v|x|X)*$"
                                                onChange={(e) => setNicNo(e.target.value)}
                                                //  onBlur={(e) => handleUpdateNicNo(e)}
                                                defaultValue=""
                                                value={nicno}
                                                ref={nicRef}
                                            />

                                            <p data-aos="fade-down">
                                                <span className="has-error">{nicErrMsg}</span>
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <div className="row payment-method-wrapper">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <h4>Payment Method</h4>
                                    </div>
                                    {paymentReference ? (
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="radio"
                                                    checked={paymentOption == 3}
                                                    value="3"
                                                    onClick={(e) =>
                                                        handlePaymenOptionOnChange(e.target.value)
                                                    }
                                                />

                                                <span>
                                                  <img
                                                      src={require('../../assets/images/ipay/ipay-logo.svg')}
                                                      className="img-responsive ipay-logo"
                                                      alt="Image"
                                                  />
                                                    {t('iPay Payment')}
                                                 </span>
                                            </label>
                                        </div>
                                    ) : (
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="radio"
                                                    checked={paymentOption == 2}
                                                    value="2"
                                                    onClick={(e) =>
                                                        handlePaymenOptionOnChange(e.target.value)
                                                    }
                                                />

                                                <span>
                                                  {t('Account Blance Payment', {
                                                      account_balance: accountBalance.toFixed(2),
                                                  })}{' '}
                                                </span>
                                            </label>

                                            {paymentOption == 2 && customerPremium > 0.0 ? (
                                                <p className="has-warning-balance">
                                                    {t(
                                                        'Charge ' +
                                                        customerPremium +
                                                        '% For Ceylinco "Ananthaya" Insuarance'
                                                    )}
                                                </p>
                                            ) : (
                                                ''
                                            )}

                                            {tab == 1 &&
                                                !topUpOpen &&
                                                accountBalance < lottery.ticket_price * ticketCount && (
                                                    <a
                                                        className="topup-click"
                                                        onClick={(e) => setTopUpOpen(!topUpOpen)}
                                                    >
                                                        {t('Topup now')}
                                                    </a>
                                                )}

                                            {tab != 1 &&
                                                !topUpOpen &&
                                                accountBalance <
                                                lottery.ticket_price * selectedTickets.length && (
                                                    <a
                                                        className="topup-click"
                                                        onClick={(e) => setTopUpOpen(!topUpOpen)}
                                                    >
                                                        {t('Topup now')}
                                                    </a>
                                                )}

                                            <img
                                                src={require('../../assets/images/payments-banner.jpg')}
                                                className="img-responsive"
                                                alt="Image"
                                            />
                                        </div>
                                    )}

                                    {/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                      {paymentDisable == true ? (
                                        <>
                                          <label>
                                            <input
                                              type="radio"
                                              name="radio"
                                              checked={paymentOption == 1}
                                              value="1"
                                              onClick={(e) =>
                                                handlePaymenOptionOnChange(e.target.value)
                                              }
                                            />
                                            <span>{t('Add to my mobile bill')}</span>
                                          </label>
                                          <p>{userMobile}</p>
                                          {paymentOption == 1 && (
                                            <p className="has-warning-balance">
                                              {t('Mobile Tax Statment', {
                                                convenience_fee: CONVENIENT_FEE,
                                              })}
                                            </p>
                                          )}
                                        </>
                                      ) : ''}

                                    </div> */}
                                </div>

                                {topUpOpen && !paymentReference && (
                                    <>
                                        <div className="row topup-wrapper row-clr" id="topup-wrapper">
                                            <TopupForm
                                                open={open1}
                                                total={
                                                    tab == 1
                                                        ? parseFloat(
                                                            lottery.ticket_price * ticketCount +
                                                            (ceylincoOnoActiveStatus === 1 &&
                                                            ceylincoOnoActiveStatus != null
                                                                ? lottery.ticket_price *
                                                                ticketCount *
                                                                (customerPremium / 100)
                                                                : 0) -
                                                            accountBalance
                                                        ).toFixed(2)
                                                        : tab == 2 && selectedTickets.length > 0
                                                            ? parseFloat(
                                                                lottery.ticket_price * selectedTickets.length +
                                                                lottery.ticket_price *
                                                                selectedTickets.length *
                                                                (ceylincoOnoActiveStatus === 1 &&
                                                                ceylincoOnoActiveStatus != null
                                                                    ? customerPremium / 100
                                                                    : 0) -
                                                                accountBalance
                                                            ).toFixed(2)
                                                            : parseFloat(MINIMUM_TOPUP_AMOUNT).toFixed(2)
                                                }
                                                handleBuyTickets={handleBuyTickets}
                                                handleClose={handleTopupClose}
                                                tickets={ticketReqeust}
                                                subscriptionRequest={{}}
                                                handlErrors={handleTopupFormErrors}
                                                lotteryName={lottery.name}
                                                readOnly={true}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="buy-ticket-wrapper">
                                    {errMsg && (
                                        <p ref={errRef} aria-live="assertive">
                                          <span className={errMsg ? 'has-error' : 'offscreen'}>
                                            {errMsg}
                                          </span>
                                        </p>
                                    )}

                                    <div className="row row-clr button-wrapper">
                                        <BlockUi tag="div" blocking={blocking}>

                                            {!topUpOpen && !paymentReference && paymentOption == 2 && (
                                                <button
                                                    type="submit"
                                                    className="button-type-7"
                                                    disabled={
                                                        (tab == 2 && selectedTickets.length == 0) ||
                                                        (tab == 1 && ticketCount == 0)
                                                    }
                                                >
                                                    {' '}
                                                    {t('Buy Ticket(s)')}
                                                </button>
                                            )}

                                            {paymentReference && paymentOption == 3 && (
                                                <button
                                                    type="submit"
                                                    className="button-type-7"
                                                    disabled={
                                                        (tab == 2 && selectedTickets.length == 0) ||
                                                        (tab == 1 && ticketCount == 0)
                                                    }
                                                >
                                                    {' '}
                                                    {t('Buy Ticket(s)')}
                                                </button>
                                            )}

                                            {topUpOpen && !paymentReference && paymentOption == 2 && (
                                                <button
                                                    type="submit"
                                                    className="button-type-7"
                                                    disabled={
                                                        (tab == 2 && selectedTickets.length == 0) ||
                                                        (tab == 1 && ticketCount == 0)
                                                    }
                                                >
                                                    {' '}
                                                    {t('Top Up & Buy Ticket(s)')}
                                                </button>
                                            )}

                                            {paymentOption == 1 && !showConfirm && (
                                                <button
                                                    disabled={
                                                        (tab == 2 && selectedTickets.length == 0) ||
                                                        (tab == 1 && ticketCount == 0)
                                                    }
                                                    className="button-type-7"
                                                    // onClick={() => setShowConfirm(true)}
                                                    type="submit"
                                                >
                                                    {' '}
                                                    {t('Buy Ticket(s)')}
                                                </button>
                                            )}

                                            {!paymentReference && tab == 1 && (
                                                <>
                                                    <Box mt={1}>
                                                        <button
                                                            onClick={() => handleAddToCart()}
                                                            type="button"
                                                            className="button-type-7"
                                                            disabled={
                                                                (tab == 2 && selectedTickets.length == 0) ||
                                                                (tab == 1 && ticketCount == 0)
                                                            }
                                                        >
                                                            <AddShoppingCartIcon sx={{fontSize: 16}}/>
                                                            {t('Add To Cart')}
                                                        </button>
                                                    </Box>
                                                </>
                                            )}
                                            {!paymentReference && tab == 2 && (
                                                <>
                                                    <Box mt={1}>
                                                        <Link
                                                            to="/cart"
                                                            type="button"
                                                            className="button-type-7"
                                                            disabled={
                                                                (tab == 2 && selectedTickets.length == 0) ||
                                                                (tab == 1 && ticketCount == 0)
                                                            }
                                                        >
                                                            <AddShoppingCartIcon sx={{fontSize: 16}}/>
                                                            {t('Go To Cart')}
                                                        </Link>
                                                    </Box>
                                                </>
                                            )}
                                        </BlockUi>
                                    </div>

                                    <p>
                                        <a target="_blank" href="/terms">
                                            {t('Terms and conditions apply')}
                                        </a>
                                    </p>
                                </div>

                                {showConfirm && (
                                    <div
                                        className="row confirm-wrapper row-clr"
                                        id="confirm-wrapper"
                                    >
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="row bottom-wrapper">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p>{t('Do you wish to add ' + ticketCount + ' tickets to cart?')}</p>
                                                </div>
                                            </div>

                                            <div className="row bottom-wrapper">
                                                <BlockUi tag="div" blocking={blocking}>
                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 label">
                                                        <button type="button" onClick={() => handleAddToCart()}
                                                                className="button-type-10">
                                                            {t('Yes')}
                                                        </button>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-3 label">
                                                        <button
                                                            type="button"
                                                            className="button-type-8"
                                                            onClick={() => setShowConfirm(false)}
                                                        >
                                                            {t('No')}
                                                        </button>
                                                    </div>
                                                </BlockUi>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    )}

                    {token && perchaseSuccess && (
                        <div className="row popup-section">
                            <div
                                className="row heading d-none"
                            >
                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-3">
                                    <img
                                        data-dismiss="modal"
                                        src={require('../../assets/images/buy-form/back-button.png')}
                                        onClick={handleCloseQuickBuy}
                                        className="img-responsive close-button"
                                        alt="Image"
                                    />
                                </div>
                            </div>

                            <div className="confirmation-wrapper">
                                <img
                                    src={require('../../assets/images/confirmation-1.png')}
                                    className="img-responsive"
                                    alt="Image"
                                />

                                <h4>
                                    Purchased {ticketDetails.quntity} {lottery.name}
                                    <br/>
                                    Total Spent (LKR){' '}
                                    <NumberFormat
                                        value={
                                            paymentOption == 1
                                                ? parseInt(ticketDetails.amount, 10) +
                                                ticketDetails.quntity * CONVENIENT_FEE
                                                : parseFloat(ticketDetails.amount).toFixed(2)
                                        }
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        suffix={paymentOption == 1 ? '/=' : ''}
                                        prefix="Rs. "
                                    />
                                    {paymentOption == 1 && ticketCount > 0 ? ' + tax' : ''}
                                </h4>
                                {/* <h4>You Have Earned {pointDetail.earned} points.</h4> */}
                                {/* <div>
                {pointDetail.bonus ? (
                  <div>
                    <Confetti numberOfPieces={100} width={250} height={300} />
                    <h4 style={{ color: "purple" }}>You Have Earned {pointDetail.bonus} bonus points.</h4>

                  </div>
                ) : (
                  <h4 style={{ display: 'none' }}>No bonus</h4>
                )}
              </div> */}
                                {transaction && transaction.length > 0 && (
                                    <>
                                        <div className="row confirmation-row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <p> {transaction.charge_total}</p>

                                                <h5>{t('Top up amount')}</h5>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <p>{transaction.txn_id}</p>

                                                <h5>{t('Bank reference')}</h5>
                                            </div>
                                        </div>

                                        <div className="row confirmation-row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <p> {transaction.transaction_end_date}</p>

                                                <h5>{t('Invoice Date')}</h5>
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <p>{transaction.id}</p>

                                                <h5>{t('Invoice Number')}</h5>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="row confirmation-button-row">
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <Link className="button-type-8 cursor-on" to="/my-tickets">
                                            {t('Go to My Tickets')}
                                        </Link>
                                    </div>

                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <a
                                            className="button-type-7 cursor-on"
                                            onClick={() => handleBuyMoreTickets()}
                                        >
                                            {t('Buy More Tickets')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default withTranslation()(QuickBuyForm)
