import {BrowserRouter} from 'react-router-dom'
import ReactDOM from 'react-dom'
import {I18nextProvider} from 'react-i18next'
import AOS from 'aos'
import 'react-block-ui/style.css'
import 'aos/dist/aos.css'

AOS.init()
import Router from './router'
import i18n from './translation'
import {AuthProvider} from './context/AuthProvider'
import {CartProvider} from "./context/CartProvider";

const App = () => (

    <BrowserRouter basename="/">
        <AuthProvider>
            <I18nextProvider i18n={i18n}>
                <CartProvider>
                    <Router/>
                </CartProvider>
            </I18nextProvider>
        </AuthProvider>
    </BrowserRouter>
)

ReactDOM.render(<App/>, document.getElementById('root'))
