import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import AriesImage from '../../assets/images/zodiac-signs/ARIES.png';
import TaurusImage from '../../assets/images/zodiac-signs/TAURUS.png';
import GeminiImage from '../../assets/images/zodiac-signs/GEMINI.png';
import CancerImage from '../../assets/images/zodiac-signs/CANCER.png';
import LeoImage from '../../assets/images/zodiac-signs/LEO.png';
import VirgoImage from '../../assets/images/zodiac-signs/VIRGO.png';
import LibraImage from '../../assets/images/zodiac-signs/LIBRA.png';
import ScorpioImage from '../../assets/images/zodiac-signs/SCORPIO.png';
import SagittariusImage from '../../assets/images/zodiac-signs/SAGITTARIUS.png';
import CapricornImage from '../../assets/images/zodiac-signs/CAPRICORN.png';
import AquariusImage from '../../assets/images/zodiac-signs/AQUARIUS.png';
import PiscesImage from '../../assets/images/zodiac-signs/PISCES.png';
import './style.css';

const ZodiacDropdown = ({setSearchArray, searchArray, selectedOption, setSelectedOption}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [zodiacArray, setZodiacArray] = useState([]);

    const options = [
        {value: 'ARIES', label: `${t('ARI')}/මේෂ/மேஷம்`, image: AriesImage},
        {value: 'TAURUS', label: `${t('TAU')}/වෘෂභ/ரிஷபம்`, image: TaurusImage},
        {value: 'GEMINI', label: `${t('GEM')}/මිථුන/மிதுனம்`, image: GeminiImage},
        {value: 'CANCER', label: `${t('CAN')}/කටක/கடகம்`, image: CancerImage},
        {value: 'LEO', label: `${t('LEO')}/සිංහ/சிம்மம்`, image: LeoImage},
        {value: 'VIRGO', label: `${t('VIR')}/කන්‍යා/கன்னி`, image: VirgoImage},
        {value: 'LIBRA', label: `${t('LIB')}/තුලා/துலாம்`, image: LibraImage},
        {value: 'SCORPIO', label: `${t('SCO')}/වෘශ්‍චික/விருச்சிகம்`, image: ScorpioImage},
        {value: 'SAGITTARIUS', label: `${t('SAG')}/ධනු/தனுசு`, image: SagittariusImage},
        {value: 'CAPRICORN', label: `${t('CAP')}/මකර/மகரம்`, image: CapricornImage},
        {value: 'AQUARIUS', label: `${t('AQU')}/කුම්‍භ/கும்பம்`, image: AquariusImage},
        {value: 'PISCES', label: `${t('PIS')}/මීන/மீனம்`, image: PiscesImage},
    ];

    useEffect(() => {
        const initialLangArray = options.map(option => (
            `<li><img src="${option.image}" alt="" value="${option.value}"/><span>${option.label}</span></li>`
        ));
        setZodiacArray(initialLangArray);
    }, [options]);

    const handleSelect = (option) => {
        setSelectedOption(option);
        if (searchArray === []) {
            setSelectedOption(null)
        }
        setSearchArray({...searchArray, 'lagna': option.value})
        setIsOpen(false);
    };

    return (
        <div className="zodiac-select">
            <button type="button" className="btn-select" onClick={() => setIsOpen(!isOpen)}>
                {selectedOption ? (
                    <>
                        <img src={selectedOption.image} alt={selectedOption.label}
                             className="img-responsive zodiac-sign"/>
                    </>
                ) : (
                    <span>{t('lagna')}</span>
                )}
            </button>
            {isOpen && (
                <div className="b open">
                    <ul id="a">
                        {options.map(option => (
                            <li key={option.value} onClick={() => handleSelect(option)}>
                                <img src={option.image} alt={option.label} className="img-responsive zodiac-sign"/>
                                <span>{option.label}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ZodiacDropdown;