import { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import HomeBannerSlider from 'react-slick'
import LoginFrom from '../LoginForm'
import useAuth from '../../hooks/useAuth'
import YouTubeIcon from '@mui/icons-material/YouTube';
import { HashLink as Link } from 'react-router-hash-link'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import Confetti from 'react-confetti';
const HomeBanner = (props: any) => {
    const { t, banner, title, body, title3, title2, reg_button } = props
    const [open, setOpen] = useState(false)
    const [imageBanner, setImageBanner] = useState([])
    const axiosPrivate = useAxiosPrivate()
    const { auth } = useAuth()

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()

        const getData = async () => {
            try {
                const response = await axiosPrivate.get('Banner/HomeBanner', {
                    signal: controller.signal,
                })
                setImageBanner(response.data?.banner)
            } catch (err) {
                console.error(err)
            }
        }

        getData()

        return () => {
            isMounted = false
            controller.abort()
        }
    }, [])

    const handleLoginModelClose = () => {
        setOpen(false)
    }

    const handleLoginModelOpen = () => {
        setOpen(true)
    }

    const settings = {
        infinite: true,
        speed: 1500,
        autoplay: true,
        arrows: true,
        dots: true,
        autoplaySpeed: 3500,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {},
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                },
            },
        ],
    }

    return (
        <>
            <section className="home-banner-slider-section" data-aos="fade-up">
                <div className="container">

                    <HomeBannerSlider className="home-banner-slider" {...settings}>
                  
                     
                        {imageBanner.map((item: any) => (

                            <div className="row slider-row" key={item.id}>

                                <div className="col-xs-12 col-sm-6 col-md-7 col-lg-6 content-box">
                                <div className="row">
                                        <img style={{height:"350px"}} src='https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/banners/rs+40-mini-banner.jpeg' className="img-responsive" alt="Image" />
                                    
                                </div>
                                    
                                    {/* <div className='home-banner-name' dangerouslySetInnerHTML={{ __html: item.wording }} ></div> */}
                                   
                                    <div style={{ marginTop: "30px", display: 'flex' }}>
                                        <div style={{ marginTop: "5px" }}>

                                            {Object.keys(auth).length == 0 ? (
                                                <div>
                                                    {item.language == null &&
                                                        <>

                                                            <a
                                                                className="button-type-1 cursor-on" style={{ position: 'relative', top: '0', left: '15px', zIndex: 2 }}
                                                                onClick={handleLoginModelOpen}
                                                            >
                                                                {t('Register Now')}

                                                            </a>
                                                        </>
                                                    }

                                                    {item.language == 1 &&
                                                        <>

                                                            <a
                                                                className="button-type-1 cursor-on" style={{ position: 'relative', top: '0', left: '15px', zIndex: 2 }}
                                                                onClick={handleLoginModelOpen}
                                                            >
                                                                {t('ලියා පදිංචි වන්න ')}
                                                            </a>
                                                        </>
                                                    }
                                                    {item.language == 2 &&
                                                        <>
                                                            <a
                                                                className="button-type-1 cursor-on" style={{ position: 'relative', top: '0', left: '15px', zIndex: 2 }}
                                                                onClick={handleLoginModelOpen}
                                                            >
                                                                {t('இப்போது பதிவு செய்யுங்கள்')}


                                                            </a>
                                                        </>
                                                    }

                                                </div>
                                            ) : (
                                                <Link className="button-type-1 cursor-on" style={{ position: 'relative', top: '0', left: '15px', zIndex: 2 }} to="/my-profile">{t('‘ANANTHAYA’ INSURANCE')}</Link>
                                            )}</div>


                                        <div style={{ marginBottom: "6px", marginLeft: "4px" }}>
                                            <a
                                                href="https://www.youtube.com/channel/UC_DjcRoxlrL44nBbeeSYnkw"
                                                target="_blank"
                                            >

                                                <button className="button-type-12 cursor-on" style={{ position: 'relative', top: '0', left: '15px', zIndex: 2 }}>
                                                    <YouTubeIcon className="img-responsive2" fontSize='large' />
                                                    <br className="br-on" />
                                                    <span>{t('Help Guide')}</span>
                                                </button>
                                            </a>

                                        </div>

                                    </div>
                                    {/* <div className='slider_image' style={{ position: 'relative', width: '100%', margin: 'auto', height: 150, bottom: 450, right: 103, height: 150 }}>
                                        <img src='https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/banners/buddhistflag.png' style={{ width: '600px', opacity: 0.6, position: 'absolute', top: 133, bottom: 0, left: 0, right: 0, }} alt="Vesak Image" />
                                        <img src='https://ccwpitbucket.s3.us-east-2.amazonaws.com/web-cdn/images/banners/vesak+images/vesak-day-holiday.png' style={{ width: '950px', opacity: 0.1, position: 'absolute', top: 180, bottom: 0, left: 200, right: 0, }} alt="Vesak Image" />
                                       
                                    </div> */}
                                </div>

                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5 img-wrapper">
                                    <div className="row">
                                        <img src={item.link} className="img-responsive" alt="Image" />
                                    </div>
                                </div>

                            </div>
                        ))}

                    </HomeBannerSlider>
                </div>

            </section>

            <LoginFrom handleClose={handleLoginModelClose} open={open} title={t('Register')} />
        </>
    )
}

export default withTranslation()(HomeBanner)
