import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { withTranslation } from 'react-i18next'
import dateFormat from 'dateformat'
import { HashLink as Link } from 'react-router-hash-link'
import Moment from 'moment'
//import { Link } from 'react-router-dom'

var today = new Date()
var dd = String(today.getDate()).padStart(2, '0')
var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
var yyyy = today.getFullYear()
today = yyyy + '-' + mm + '-' + dd

const LotteryResult = (props: any) => {
  const { t, page } = props

  const axiosPrivate = useAxiosPrivate()
  const [lotteries, setLotteries] = useState()
  const [results, setResults] = useState()
  const [selectedLottery, setSelectedLottery] = useState({})
  const [quickOpen, setQuickOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [blocking, setBlocking] = useState(true)
  const [scheduleOpen, setScheduleOpen] = useState(false)

  const [names, setNames] = useState()
  const [usernames, setUsernames] = useState()

  const [data_content, setContent] = useState()

  const [drawDate, setDrawDate] = useState('')
  const [drawId, setDrawId] = useState('')

  const [dateDetails, setDateDetails] = useState()

  const [lotteryList, setLotteryList] = useState({})
  const [drawList, setDrawList] = useState({})
  const [todaydate, setToday] = useState(today)
  const [lotteryId, setLotteryId] = useState('')
  const [drawDatePlaceholder, setDrawDatePlaceholder] = useState()

  const [drawsNumbers, setDrawsNumbers] = useState({})

  const [isDisabledPrev, setIsDisabledPrev] = useState(false)
  const [from, setFrom] = useState('0')
  const [to, setTo] = useState('0')
  const [totalRec, setTotalrec] = useState(0)
  const [isDisabledNext, setIsDisabledNext] = useState(false)
  const [ticketPageCount, setTicketPageCount] = useState(0)
  const [prevPage, setPrevpage] = useState('')
  const [nextPageUrl, setNextpageUrl] = useState('')
  const [lastPageUrl, setLastpageUrl] = useState('')
  const [lastPage, setLastpage] = useState('')
  const [token, setToken] = useState('')
  const [showTopBtn, setShowTopBtn] = useState(false)
  const [drawListContent, setDrawListContent] = useState('')
  const [drawSelected, setDrawSelected] = useState({ selected: '' })

  const goToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const dates = []
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const PAGE_COUNT = 30

  useEffect(async () => {
    //mobileRef.current?.focus()
    let isMounted = true
    handlePage(1)
    getLotteries()
    return () => {
      isMounted = false
      controller.abort()
    }
  }, [])

  const createBody = async (response) => {
    const today = Moment().format('MMMM Do YYYY')
    try {
      var date_details = response.data[0]
      var date_details = response.data[0]
      var dates_data = JSON.parse(date_details)

      var data2 = response.data[1].data

      const yesterday = Moment().subtract(1, 'days')
      const formattedYesterday = Moment(yesterday).format('MMMM Do YYYY')

      setContent('')

      const content = []
      var i = 0
      var k = 0

      if (data2 == '') {
        content.push(
          <div className="row row-clr results-row">
            <h1>No Lottery Results Available</h1>
          </div>
        )
      } else {
        dates_data.date.forEach((dates, index2) => {
          const formattedDate = Moment(dates).format('MMMM Do YYYY')
          if (today == formattedDate) {
            content.push(
              <div key={index2} className="main-date-header">
                {formattedDate} (Today)
              </div>
            )
          } else if (formattedYesterday == formattedDate) {
            content.push(
              <div key={index2} className="main-date-header">
                {formattedDate} (Yesterday)
              </div>
            )
          } else {
            content.push(
              <div key={index2} className="main-date-header">
                {formattedDate}
              </div>
            )
          }

          content.push(
            <div className="row row-clr results-row">
              {dates_data[dates].forEach((draw_list_ids, index) => {
                var draw = data2[draw_list_ids]

                content.push(
                  <div
                    key={draw.created_at}
                    data-aos={
                      index % 3 == 0
                        ? 'fade-left'
                        : index % 3 == 1
                        ? 'fade-up'
                        : 'fade-right'
                    }
                    className={
                      index % 3 == 1
                        ? 'col-xs-12 col-sm-12 col-md-12 col-lg-4 inner-wrapper middleone'
                        : 'col-xs-12 col-sm-12 col-md-12 col-lg-4 inner-wrapper'
                    }
                  >
                    <div className="row top-wrapper">
                      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 left">
                        <p>{draw.name}</p>
                      </div>

                      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 right">
                        <div className="row row-clr">
                          <p>
                            <span>
                              {draw.draw_number && (
                                <>{draw.draw_number}&nbsp;&nbsp;</>
                              )}
                            </span>
                            {t(
                              draw.draw_date &&
                                dateFormat(draw.draw_date, 'yyyy-mm-dd')
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row buttom-wrapper clerfix ">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                        <img
                          src={draw.image_url}
                          className="img-responsive  result-lot-img"
                          alt="Image"
                        />

                        <ul className="clerfix ">
                          {draw.letter && (
                            <li className="supper-class results-numbers">{draw.letter}</li>
                          )}

                          {draw.super_number && (
                            <li className="supper-number-class results-numbers"   style={{ backgroundColor: '#f00' ,color:"white"}}>
                              {draw.super_number}
                            </li>
                          )}
                       
                          {draw.lagna && (
                            <>
                              <li className="supper-lagna-class results-numbers">
                                <img
                                  src={require('../../assets/images/zodiac-signs/' +
                                    draw.lagna +
                                    '.png')}
                                  className="img-responsive"
                                />
                                <div className='lagna-name-class'> {draw.lagna}</div>
                              </li>
                            </>
                          )}

                          {draw.winning_numbers.split(' ').map((win_no) => (
                            <>
                              {' '}
                              <li className='results-numbers'> {win_no} </li>{' '}
                            </>
                          ))}
                          <br />
                          
                        </ul>
                        <ul className="clerfix">
                         

                         {/* {draw.special_no &&(

                          <><p className='special-result-numbers'>{draw.special_no}</p></>
                         )} */}
                       
                       <div className="special-numbers-container">
    {/* {draw.special_no &&
      draw.special_no.split('').map((special_no) => (
        <p key={index} className="special-result-numbers">
          {special_no}
        </p>
      ))} */}
      {draw.special_no &&
  draw.special_no.split('').map((special_no) => (
    special_no !== ' ' && (
      <p key={index} className="special-result-numbers">
        {special_no}
      </p>
    )
  ))}

  </div>
                          
                        </ul>
                        
                        
                      </div>
                    </div>
                    
                  </div>
                )
              })}
            </div>
          )

          i++
          k++
        })
      }

      setContent(content)
      console.warn(content)
    } catch (err) {
      console.log('Error', err)
    }
  }

  const handlePage = async (page_no: number) => {
    // console.log(page_no);
    if (page_no == 0) {
      setTicketPageCount(0)
    }
    // console.log("pageNo="+page_no)

    try {
      if (props.page == 'home') {
        var record_per_page = 12
      } else {
        var record_per_page = 30
      }
   
      const response = await axiosPrivate.post(
        'winning' + (page_no > 0 ? '?page=' + page_no : ''),
        
        {
          page_count: record_per_page,
          draw_id: drawId,
          draw_date: drawDate,
          lottery_id: lotteryId,
        },
        
      )

      //console.log(response.data[1].data.length)
      // console.warn(response.data);
      setTicketPageCount(page_no)
      console.log("pageNo="+page_no)
      if (response.data[1]?.prev_page_url != null) {
        setIsDisabledPrev(false)
      } else {
        setIsDisabledPrev(true)
      }

      if (response.data[1]?.next_page_url != null) {
        setIsDisabledNext(false)
      } else {
        setIsDisabledNext(true)
      }

      if (response.data[1]?.data.length == 0) {
        createBody(response)
        //setPurchaseTicket(response.data[1]?.data)
        setFrom(response.data[1].from)
        setTo(response.data[1].to)
        setTotalrec(response.data[1].total)
        setLastpage(response.data[1].last_page)
        setLastpageUrl(response.data[1].last_page_url)
        setNextpageUrl(response.data[1].next_page_url)
        setPerpage(response.data[1].per_page)
        setPrevpage(response.data[1].prev_page_url)
      } else {
        createBody(response)
        //setPurchaseTicket(response.data[1]?.data)
        setFrom(response.data[1].from)
        setTo(response.data[1].to)
        setTotalrec(response.data[1].total)
        setLastpage(response.data[1].last_page)
        setLastpageUrl(response.data[1].last_page_url)
        setNextpageUrl(response.data[1].next_page_url)
        setPerpage(response.data[1].per_page)
        setPrevpage(response.data[1].prev_page_url)
      }
    } catch (err) {}
  }

  const getLotteries = async () => {
    try {
      //setBlocking(true)
      const response = await axiosPrivate.get('lottery_list')
      setBlocking(false)
      setLotteryList(response.data)
    } catch (err) {}
  }

  const createDrawListBody = async (drawList) => {
    try {
      const content = []
      content.push(<option value="">Any Draw</option>)

      {
        drawList.forEach((draw, index) => {
          content.push(
            <option key={index} value={draw.id}>
              {draw.draw_number}
            </option>
          )
        })
      }

      setDrawListContent(content)
    } catch (err) {}
  }

  const handleDraw = async (e: any) => {
    // alert(e.target.value)
    setLotteryId(e.target.value)

    if (props.page == 'home') {
      var record_per_page = 10
    } else {
      var record_per_page = 30
    }

    setDrawListContent('')
    // handlePage(1);
    try {
      setBlocking(true)
      const result = await axiosPrivate.post('winning', {
        lottery_id: e.target.value,
        draw_date: drawDate,
        page_count: record_per_page,
      })

      createBody(result)
      setBlocking(false)
      // setLotteryList(response);
      // console.log(result.data)
      // alert(result.data["0"])
      setDrawsNumbers(result.data)

      if (result.data[2]) {
        createDrawListBody(result.data[2])
      }
    } catch (err) {}
  }

  // const handleDrawClick = async(e : any)=> {
  //    setDrawId(e.target.value);
  //  };

  const handleSearch = async () => {
    // alert(e.target.value)
    //setDrawId(e.target.value);
    //setLotteryId(e.target.value);
    // handlePage(1);

    // draw_id:drawId,
    //    draw_date:drawDate,
    //    lottery_id:lotteryId,
    try {
      setBlocking(true)
      const result = await axiosPrivate.post('winning', {
        lottery_id: lotteryId,
      })

      createBody(result)
      setBlocking(false)
      // setLotteryList(response);
      // console.log(result.data)
      // alert(result.data["0"])
      setDrawsNumbers(result.data)

      if (result.data[2]) {
        createDrawListBody(result.data[2])
      }
    } catch (err) {}
  }

  const dateSelect = (e) => {
    setDrawDate(e.target.value)
    setDrawId('')
    setDrawSelected({ selected: '' })
    setDrawDatePlaceholder(e.target.value)
    //setDrawListContent("")
  }

  useEffect(() => {
    //console.log('Do something after counter has changed', drawId);
    handlePage(1)
  }, [drawDate])

  const drawSelect = (e) => {
    setDrawId(e.target.value)
    setDrawDate('')
    setDrawSelected(e.target.value)
    setDrawDatePlaceholder('MM/DD/YYYY')
  }

  useEffect(() => {
    //console.log('Do something after counter has changed', drawId);
    handlePage(1)
  }, [drawId])

  // function PaginationContent(props){
  //    return(
  //    <div className="text-center">
  //    <ul className="pagination ">
  //    <li className={isDisabledPrev == true  ? 'disabled' : ''}>
  //       <a  onClick={() =>{isDisabledPrev == true  ? 'disabled' : goToTop(); handlePage(ticketPageCount-1)}}>&lt;</a>
  //    </li>
  //    <li>
  //       <p>No Of Rows {t(from)} - {t(to)} of {t(totalRec)}</p>
  //    </li>
  //    <li className={isDisabledNext == true  ? 'disabled' : ''}>
  //       <a  onClick={() =>{isDisabledNext == true  ? 'disabled' : goToTop(); handlePage(ticketPageCount+1)}}>&gt;</a>
  //    </li>
  //    </ul>
  //    </div>)
  // }

  return (
    <>
      <section
        className={
          page == 'home'
            ? 'lottery-results-section-home'
            : 'lottery-results-section'
        }
      >
        <div className="container">
          <div data-aos="fade-up" className="row row-clr">
            <h3>Recent Lottery Results</h3>
          </div>
          {page == 'result' ? (
            <div className="row search-row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-sm-1 col-md-1 col-lg-1"></div>

                  <div
                    data-aos="fade-left"
                    className="col-xs-12 col-sm-3 col-md-3 col-lg-3"
                  >
                    <label>Lottery</label>
                    <select
                      name=""
                      id="input"
                      className="form-control"
                      required="required"
                      onChange={handleDraw}
                    >
                      <option value="">
                        {lotteryId?.length
                          ? 'All loteries'
                          : 'Select the lottery'}
                      </option>
                      {lotteryList?.length ? (
                        lotteryList.map((item: any, index: number) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No Lottery</option>
                      )}
                    </select>
                  </div>

                  <div
                    data-aos="fade-up"
                    className="col-xs-12 col-sm-3 col-md-3 col-lg-3"
                  >
                    <label>Draw Number</label>
                    <select
                      name=""
                      id="input"
                      className="form-control"
                      value={drawSelected}
                      required="required"
                      onChange={drawSelect}
                    >
                      <option value="" selected disabled>
                        {lotteryId?.length
                          ? 'Please Select Draw Number'
                          : 'Please Select Lottery'}
                      </option>

                      {drawListContent}
                    </select>
                  </div>

                  <div
                    data-aos="fade-right"
                    className="col-xs-12 col-sm-3 col-md-3 col-lg-3"
                  >
                    <label>Date</label>
                    <div
                      className="input-group date"
                      data-date-format="dd.mm.yyyy"
                    >
                      <input
                        type="date"
                        className="form-control"
                        value={drawDatePlaceholder}
                        max={todaydate}
                        onChange={dateSelect}
                      />
                    </div>
                  </div>

                  <div className="col-sm-1 col-md-1 col-lg-1"></div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="row row-clr results-row">{data_content}</div>
        </div>

        {page == 'result' ? (
          <div className="row row-clr">
            <div className="text-center">
              <ul className="pagination ">
                <li className={isDisabledPrev == true ? 'disabled' : ''}>
                  <a
                    onClick={() => {
                      isDisabledPrev == true ? 'disabled' : goToTop()
                      handlePage(ticketPageCount - 1)
                    }}
                  >
                    &lt;
                  </a>
                </li>
                <li>
                  <p>
                    No Of Rows {t(from)} - {t(to)} of {t(totalRec)}
                  </p>
                </li>
                <li className={isDisabledNext == true ? 'disabled' : ''}>
                  <a
                    onClick={() => {
                      isDisabledNext == true ? 'disabled' : goToTop()
                      handlePage(ticketPageCount + 1)
                    }}
                  >
                    &gt;
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="row button-row-1">
          {page == 'home' ? (
            <Link
              to="/results"
              data-aos="fade-up"
              className="button-type-5 pull-right aos-init aos-animate"
            >
              View more results
            </Link>
          ) : (
            ''
          )}
        </div>
      </section>
    </>
  )
}

export default withTranslation()(LotteryResult)
