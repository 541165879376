import React, {useEffect, useRef} from "react";
import {Modal} from "react-bootstrap";

const ThreeDSChallenge = ({creq, actionUrl, show3DS, closeModal, saveCard}) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const form = document.createElement("form");
        form.method = "POST";
        form.action = actionUrl;
        form.target = "challengeFrame";

        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "creq";
        input.value = creq;
        form.appendChild(input);

        const iframe = document.createElement("iframe");
        iframe.name = "challengeFrame";
        iframe.width = "100%";
        iframe.height = "100%";
        iframe.style.border = "none";

        document.getElementById("threedsChallengeRedirect").appendChild(form);
        document.getElementById("threedsChallengeRedirect").appendChild(iframe);

        form.submit();
        form.remove();

        return () => iframe.remove();
    }, [creq, actionUrl]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [closeModal]);

    return (
        <Modal
            className={`register-main-popup ${show3DS ? 'in' : ''}`}
            show={show3DS}
            backdrop="static"
        >
            {saveCard === 'Y' &&
                <div>
                    <div>
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 img-wrapper" style={{padding: '3px'}}>
                            <img
                                src={require('../../assets/images/popup/exclamation-warning-round-red-icon.svg')}
                                className="img-responsive"
                                alt="Image"
                            />
                        </div>
                        <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 content-wrapper">
                            <h5>
                                <strong>Note:</strong> Rs. 50 will be temporarily deducted when saving your card. This
                                amount is fully refundable.
                            </h5>
                        </div>
                    </div>
                </div>
            }
            <div ref={modalRef} id="threedsChallengeRedirect" style={{height: "80vh"}}/>
        </Modal>
    );
};

export default ThreeDSChallenge;
