import React, {useState, useEffect, useRef} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {withTranslation} from 'react-i18next'
import {Modal} from 'react-bootstrap'
// import RangeSlider from 'react-bootstrap-range-slider'
import {HashLink as Link} from 'react-router-hash-link'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import TopupForm from '../../components/TopupForm'
import NumberFormat from 'react-number-format'
import BlockUi from 'react-block-ui'
import axios from '../../api/axios'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import ReactSpeedometer from 'react-d3-speedometer'
import IsPurchasedConversionTracking from '../IsPurchasedConversionTracking'
import LoginFrom from "../LoginForm";
// import { number } from 'yup'
// import { rgbToHex } from '@mui/material'
const regex5 = /^([0-9]{12})/

const regex6 = /^([0-9]{9})([v|x|V|X])/

const PAGE_COUNT = process.env.TICKET_PAGE_COUNT
const DEFAULT_DIALCODE = process.env.DEFAULT_DIALCODE
const DEFAULT_TICKET_COUNT = process.env.DEFAULT_TICKET_COUNT
const ScheduleForm = (props: any) => {
    const {
        t,
        title,
        scheduleOpen,
        handleClose,
        lottery,
        scheduleBuyLoginOpen,
        paymentReference,
        isLoggedOut
    } = props

    const navigate = useHistory()
    const location = useLocation()
    const axiosPrivate = useAxiosPrivate()
    const from = location.state?.from?.pathname || '/'
    const {setAuth, auth} = useAuth()
    //const mobileRef = useRef()
    const errRef = useRef()
    const [mobile, setMobile] = useState('')
    const [open, setOpen] = useState(false)
    const [nicno, setNicNo] = useState('')
    const [otp, setOtp] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [userMobiles, setUserMobiles] = useState([])
    const [userMobile, setUserMobile] = useState('')
    const [sentOtp, setSentOtp] = useState(false)
    const [mobileNo, setMobileNo] = useState('')
    const [ticketCount, setTicketCount] = useState(DEFAULT_TICKET_COUNT)
    const [startDate, setStartDate] = useState('')
    const [recurringPlan, setRecurringPlan] = useState('daily')
    const [recurringPeriod, setRecurringPeriod] = useState('')
    const [showConfirm, setShowConfirm] = useState(false)
    const [user, setUser] = useState({})
    const [drawNo, setDrawNo] = useState(0)
    const [paymentOption, setPaymentOption] = useState(paymentReference ? 3 : 2)
    const [perchaseSuccess, setPerchaseSuccess] = useState(false)
    const [token, setToken] = useState('')
    const [accountBalance, setAccountBalance] = useState(0)
    const [blocking, setBlocking] = useState(false)
    const [accountBalanceAlert, setAccountBalanceAlert] = useState(false)
    const [nicErrMsg, setNIcErrMsg] = useState('')
    const [topUpOpen, setTopUpOpen] = useState(false)
    const [ticketReqeust, setTicketReqeust] = useState({})
    const [tab, setTab] = useState(1)
    const [open1, setOpen1] = useState(false)
    const [subscriptionRequest, setSubscriptionRequest] = useState({})
    const [subscriptionResult, setSubscriptionResult] = useState({})
    const [transaction, setTransaction] = useState({});
    const [subscription, setSubscription] = useState({});
    const [topupFormOpen, setTopupFormOpen] = useState(false)
    const [ticketMeter, setTicketMeter] = useState(5)
    const date = new Date()
    const defaultValue = date.toLocaleDateString('en-CA')
    const [paymentDisable, setpaymentDisable] = useState(false)

    const nicRef = useRef(null)
    const [timer, setTimer] = useState(60)
    const id = useRef(null)
    const clear = () => {
        window.clearInterval(id.current)
    }
    const countTimer = () => {
        id.current = window.setInterval(() => {
            setTimer((time) => time - 1)
        }, 1000)
        return () => clear()
    }

    useEffect(() => {
        if (timer === 0) {
            clear()
        }
    }, [timer])

    useEffect(() => {
        setPerchaseSuccess(false)
        setShowConfirm(false)
    }, [])

    useEffect(() => {
        setShowConfirm(false)
        setPaymentOption(paymentReference ? 3 : 2)
    }, [handleClose, open])

    useEffect(() => {
        setNIcErrMsg('')
    }, [nicno])

    useEffect(() => {
        setErrMsg('')
    }, [mobile, otp, startDate])

    useEffect(() => {
        if (open) {
            if (lottery.lottery_id == 25) {
                setpaymentDisable(false)
            } else {
                setpaymentDisable(true)
            }
            let user = JSON.parse(localStorage.getItem('user'))
            let atkn = localStorage.getItem('atkn')
            setStartDate(defaultValue)
            setErrMsg('')
            setUser(user)
            setToken(atkn)
            setTopUpOpen(false)
            setTicketMeter(5)
            user != undefined ? fetchUserMobiles(user.id) : ''
            user != undefined ? getUserBalance(user.id) : ''
            setPerchaseSuccess(false)
            setAccountBalanceAlert(false)
            setTicketCount(DEFAULT_TICKET_COUNT)
        }
    }, [open])

    useEffect(() => {
        if (scheduleOpen === true) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [scheduleOpen]);

    useEffect(() => {
        if (isLoggedOut === true) {
            setOpen(false)
        }
    }, [isLoggedOut]);

    const fetchUserMobiles = async (uid: number) => {
        setErrMsg('')
        if (lottery.lottery_id == undefined) return false

        try {
            const response = await axiosPrivate.get('/user/mobiles/view/' + uid, {})

            const userMobiles = response?.data?.mobiles
            setUserMobiles(userMobiles)
            if (userMobiles[0]) setUserMobile(userMobiles[0])

            // setDraws(curDrows)
        } catch (err) {
            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors[0])
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const getUserBalance = async (id: any) => {
        try {
            const response = await axiosPrivate.get('user/apcredit/balance/' + id, {})

            let accBal = parseFloat(response?.data?.acccount_balance)
            if (accBal) setAccountBalance(accBal)
            else accBal = 0

            // setAccountBalance(accBal)
            if (accBal < lottery.ticket_price * ticketCount) {
                setTopUpOpen(true)

                setAccountBalanceAlert(true)
            } else {
                setTopUpOpen(false)
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors)
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }
    const handleSelectTicketCount = (qty: number) => {
        // alert(qty)

        setTicketCount(qty)

        if (qty <= 20) {
            setTicketMeter(qty)
        } else {
            setTicketMeter(20)
        }

        if (
            (tab == 1 &&
                accountBalance < lottery.ticket_price * qty &&
                paymentOption == 2) ||
            (tab != 1 &&
                accountBalance < lottery.ticket_price * selectedTickets.length &&
                paymentOption == 2)
        )
            setTopUpOpen(true)
        else setTopUpOpen(false)

        setTicketCount(qty)
    }

    const handleTopupClose = () => {
        setTopUpOpen(false)
    }
    const handlePaymenOptionOnChange = (value: any) => {
        // console.log(value)
        setPaymentOption(value)
        setShowConfirm(false)
        if (value == 1) {
            setTopUpOpen(false)
        } else if (
            (tab == 1 && accountBalance < lottery.ticket_price * ticketCount) ||
            (tab != 1 &&
                accountBalance < lottery.ticket_price * selectedTickets.length)
        ) {
            setTopUpOpen(true)
        }
    }

    const handleUpdateNicNo = async (postArr) => {
        if (nicno.length < 10) {
            setBlocking(false)
            return false
        }

        try {
            const response = await axiosPrivate.post('user/edit/nic', {
                id: user.id,
                nic: nicno,
            })
            const newUser = response?.data?.user
            if (newUser) {
                localStorage.setItem('user', JSON.stringify(newUser))
                setAuth({user: newUser})
                if (topUpOpen) {
                    // alert('H1')
                    setSubscriptionRequest(postArr)
                } else {
                    handleSubscriptionEX(postArr)
                }
            }
        } catch (err) {
            if (!err?.response) {
                setNIcErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                if (Array.isArray(err.response.data?.errors))
                    setNIcErrMsg(err.response.data?.errors[0])
                else setNIcErrMsg(err.response.data?.errors)
                nicRef.current?.focus()
            } else {
                setNIcErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    const handleSubmitSubscription = async (e: any) => {
        e.preventDefault()

        if (user.nicno == null && nicno.length < 10) {
            setNIcErrMsg(t('*Please enter valid NIC number.'))
            setBlocking(false)
            return false
        }
        if (nicno == null && nicno.length < 10) {
            setNIcErrMsg(t('*Please enter valid NIC number.'))
            setBlocking(false)
            return false
        }

        // alert(regex5.test(nicno))
        if (regex5.test(nicno) == false && regex6.test(nicno) == false && regex5.test(user.nicno) == false && regex6.test(user.nicno) == false) {
            setNIcErrMsg('*Please enter valid NIC number.')
            setBlocking(false)
            return false;
        }

        const count = ticketCount
        let ticketsNew = []
        setBlocking(true)

        let postArr = {
            lottery_id: lottery.lottery_id,
            user_id: user.id,
            payment_option: paymentOption,
            start_time: startDate,
            ticket_quantity: count,
            package_name: recurringPlan,
            run_date: recurringPeriod,
            channel: 'web',
            mobile_no:
                userMobile.substr(0, 2) == DEFAULT_DIALCODE
                    ? userMobile.substr(2)
                    : userMobile,
        }

        if (user.nicno == null && nicno.length > 9) {
            handleUpdateNicNo(postArr)
        } else {
            handleSubscriptionEX(postArr)
            // console.warn(errors)
        }
    }

    const handleSubscriptionEX = async (postArr: any) => {
        alert('set Subscription')
        try {
            const response = await axiosPrivate.post(
                'user/subscription/create',
                postArr
            )

            const subscription = response?.data?.subscription

            setSubscription(subscription)

            if (topUpOpen) {
                setSubscriptionRequest(postArr)
                return
            }

            setBlocking(false)
            setPerchaseSuccess(true)
            setTopupFormOpen(true)
        } catch (err) {
            // alert(!err?.response)
            setBlocking(false)
            if (!err?.response) {
                setErrMsg(t('No Server Response'))
            } else if (err.response?.status === 422) {
                setErrMsg(err.response.data?.errors)
            } else {
                setErrMsg(err.response.data?.errors?.message)
            }
        }
    }

    // const handleSubmitStep2 = async (e: any) => {
    //     e.preventDefault()
    //     try {
    //         if (otp.trim().length == 0) {
    //             setErrMsg(t('Please enter your otp code.'))
    //             return
    //         }
    //         let mobilex = mobile.trim().replace('+', '')
    //         setBlocking(true)
    //         const response = await axios.post(
    //             'customer_login',
    //             JSON.stringify({
    //                 dialcode: '+' + DEFAULT_DIALCODE,
    //                 telephone:
    //                     mobilex.substr(0, 1) == DEFAULT_DIALCODE
    //                         ? mobilex.substr(2)
    //                         : parseInt(mobilex),
    //                 otp_code: otp,
    //             }),
    //             {
    //                 headers: {'Content-Type': 'application/json'},
    //                 // withCredentials: true
    //             }
    //         )
    //
    //         const token = response?.data?.token
    //
    //
    //         const tokenx = response?.data?.token
    //         if (tokenx) {
    //             const userx = response?.data?.user
    //             const permissions = response?.data?.permissions
    //             const mobiles = response?.data?.mobiles
    //             const role = response?.data?.role
    //
    //             localStorage.setItem('atkn', tokenx)
    //             localStorage.setItem('user', JSON.stringify(userx))
    //             localStorage.setItem('mobiles', JSON.stringify(mobiles))
    //             localStorage.setItem('permissions', JSON.stringify(permissions))
    //             localStorage.setItem('role', role)
    //
    //             setToken(tokenx)
    //             setOpen(true)
    //
    //             setScheduleOpen(true)
    //             setUser(userx)
    //
    //             setAuth({user, permissions, token, role, mobiles})
    //             fetchUserMobiles(userx.id)
    //             setPerchaseSuccess(false)
    //         } else {
    //             setErrMsg(response.data?.errors?.otp_code)
    //         }
    //         setBlocking(false)
    //     } catch (err) {
    //         setBlocking(false)
    //         if (!err?.response) {
    //             setErrMsg(t('No Server Response'))
    //         } else {
    //             setErrMsg(err.response.data?.errors?.otp_code)
    //         }
    //
    //         errRef.current?.focus()
    //     }
    // }

    // const handleSubmitStep1 = async (e: any) => {
    //     e.preventDefault()
    //     try {
    //         let mobilex = mobile.trim().replace('+', '')
    //         if (mobilex.length == 0) {
    //             setErrMsg(t('Please enter your mobile number.'))
    //             return
    //         }
    //         setBlocking(true)
    //         const response = await axios.post(
    //             'otp/send',
    //             JSON.stringify({
    //                 mobile_no:
    //                     mobilex.substr(0, 2) != DEFAULT_DIALCODE
    //                         ? DEFAULT_DIALCODE + parseInt(mobilex)
    //                         : mobilex,
    //             }),
    //             {
    //                 headers: {'Content-Type': 'application/json'},
    //                 // withCredentials: true
    //             }
    //         )
    //
    //         setBlocking(false)
    //
    //         const smsStatus = response?.data?.sms_status
    //
    //         if (smsStatus.error == 1) {
    //             setBlocking(false)
    //             setErrMsg(t('OTP Sending Error'))
    //         } else {
    //             countTimer()
    //             setSentOtp(smsStatus.success == 1)
    //         }
    //     } catch (err) {
    //         setBlocking(false)
    //         if (!err?.response) {
    //             setErrMsg(t('No Server Response'))
    //         } else if (err.response?.status === 422) {
    //             setErrMsg(err.response.data?.errors?.attempts)
    //         } else {
    //             setErrMsg(err.response.data?.errors?.message)
    //         }
    //
    //         errRef.current?.focus()
    //     }
    // }

    const handleSubscription = (subscription: any, transaction: any) => {
        setBlocking(false)
        setPerchaseSuccess(true)

        if (subscription.errors) {
            setErrMsg(subscription.errors)
        } else {
            setPerchaseSuccess(true)
            setTransaction(transaction)
            setSubscription(subscription)
        }
    }

    const handleTopupFormErrors = (error: string) => {
        setBlocking(false)
        setErrMsg(error)
    }

    const CONVENIENT_FEE = parseFloat(process.env.CONVENIENT_FEE)
    const MINIMUM_TOPUP_AMOUNT = process.env.MINIMUM_TOPUP_AMOUNT

    const handleCloseScheduleBuy = () => {
        setOpen(false);
        handleClose()
    }

    return (
        <>
            {!token && scheduleBuyLoginOpen && (
                <LoginFrom
                    handleClose={handleClose}
                    open={!token && scheduleBuyLoginOpen}
                    title={title}
                    setOpen={setOpen}
                />
            )}

            {open &&
                <div className='container' style={{marginTop: "15px"}}>
                    <IsPurchasedConversionTracking/>

                    {token && !perchaseSuccess && (
                        <div className="row popup-section">
                            <form onSubmit={handleSubmitSubscription}>
                                <div className="row heading">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <a onClick={() => handleCloseScheduleBuy()}>
                                            <img
                                                data-dismiss="modal"
                                                src={require('../../assets/images/buy-form/back-button.png')}
                                                className="img-responsive close-button"
                                                alt="Image"
                                            />
                                        </a>
                                        <div className="row row-clr heading-row lottery-heading">
                                            <img
                                                src={`${lottery.image_file_path}`}
                                                className="img-responsive"
                                                style={{height: '45px'}}
                                                alt="Image"
                                            />
                                            <h4>
                                                {t('Subscribe')}
                                                <span> {lottery.name}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                {accountBalanceAlert && (
                                    <div
                                        className="row row-clr error-wrapper blue"
                                        data-aos="fade-down"
                                    >
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 img-wrapper">
                                            <img
                                                src={require('../../assets/images/popup/1.svg')}
                                                className="img-responsive"
                                                alt="Image"
                                            />
                                        </div>
                                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 content-wrapper">
                                            <p>
                                                {t('Account Balance Text', {
                                                    account_balance: accountBalance.toFixed(2),
                                                })}

                                                <a href="#topup-wrapper" className="topup-click">
                                                    {t('Topup now')}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="row row-clr tab-wrapper">
                                    <div className="tab-teaser">
                                        <div className="tab-main-box">
                                            <div
                                                className="tab-box "
                                                id="tab-1"
                                                style={{display: 'block'}}
                                            >
                                                <div className="row row-clr">
                                                    <h5>Number of tickets</h5>
                                                </div>
                                                <div className="row row-clr number-wrapper">
                                                    <div
                                                        className="col-xs-10 col-sm-10 col-md-10 col-lg-10 content-wrapper">
                                                        <ul className="clerfix ticket-count">
                                                            <li
                                                                onClick={() => handleSelectTicketCount(1)}
                                                                className={ticketCount == 1 ? 'supper-class' : ''}
                                                            >
                                                                1
                                                            </li>
                                                            <li
                                                                onClick={() => handleSelectTicketCount(2)}
                                                                className={ticketCount == 2 ? 'supper-class' : ''}
                                                            >
                                                                2
                                                            </li>
                                                            <li
                                                                onClick={() => handleSelectTicketCount(5)}
                                                                className={
                                                                    ticketCount == 5
                                                                        ? 'supper-number supper-class'
                                                                        : 'supper-number'
                                                                }
                                                            >
                                                                5
                                                            </li>
                                                            <li
                                                                onClick={() => handleSelectTicketCount(10)}
                                                                className={
                                                                    ticketCount == 10 ? 'supper-class' : ''
                                                                }
                                                            >
                                                                10
                                                            </li>

                                                            <li
                                                                onClick={() => handleSelectTicketCount(20)}
                                                                className={
                                                                    ticketCount != 1 &&
                                                                    ticketCount != 2 &&
                                                                    ticketCount != 5 &&
                                                                    ticketCount != 10
                                                                        ? 'supper-class other'
                                                                        : 'other'
                                                                }
                                                            >
                                                                Other
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div
                                                        className="col-xs-2 col-sm-2 col-md-2 col-lg-2 spmeter-wrapper img-wrapper">
                                                        <ReactSpeedometer
                                                            width={140}
                                                            height={96}
                                                            fluidWidth={false}
                                                            // forceRender={true}
                                                            minValue={0} //<---here
                                                            maxValue={20} //<---here
                                                            needleHeightRatio={0.6}
                                                            value={ticketMeter}
                                                            currentValueText="Winning Chance"
                                                            paddingHorizontal={0}
                                                            paddingVertical={0}
                                                            customSegmentStops={[0, 1, 2, 5, 10, 15, 20]}
                                                            segmentColors={[
                                                                '#FF0000',
                                                                '#FFA500',
                                                                '#FFFF00',
                                                                '#00FF00',
                                                                '#32CD32',
                                                                '#008000',
                                                                '#033E3E',
                                                            ]}
                                                            customSegmentLabels={[
                                                                {
                                                                    // text: '1',
                                                                    position: 'INSIDE',
                                                                    color: '#555',
                                                                },
                                                                {
                                                                    // text: '2',
                                                                    position: 'INSIDE',
                                                                    color: '#555',
                                                                },
                                                                {
                                                                    // text: '5',
                                                                    position: 'INSIDE',
                                                                    color: '#555',
                                                                    // fontSize: '19px',
                                                                },
                                                                {
                                                                    // text: '10',
                                                                    position: 'INSIDE',
                                                                    color: '#555',
                                                                },
                                                                {
                                                                    // text: '10+',
                                                                    position: 'INSIDE',
                                                                    color: '#555',
                                                                },
                                                                {
                                                                    // text: '10+',
                                                                    position: 'INSIDE',
                                                                    color: '#555',
                                                                },
                                                            ]}
                                                            ringWidth={15}
                                                            needleTransitionDuration={3333}
                                                            needleTransition="easeElastic"
                                                            needleColor={'#4169E1'}
                                                            textColor="#5f2eea"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row detail-row">
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                        <label>{t('Number of Tickets')}</label>

                                                        <input
                                                            type="number"
                                                            name="other"
                                                            id="other"
                                                            min="1"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                handleSelectTicketCount(parseInt(e.target.value))
                                                            }
                                                            defaultValue=""
                                                            value={ticketCount}
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        <label>{t('Start date')}</label>
                                                        <div
                                                            className="input-group"
                                                            data-date-format="dd.mm.yyyy"
                                                        >
                                                            <input
                                                                defaultValue={defaultValue}
                                                                min={defaultValue}
                                                                type="date"
                                                                name="startTime"
                                                                id="startTime"
                                                                className="form-control"
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                placeholder="dd.mm.yyyy"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row detail-row">
                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        <label>{t('Mobile Number')}</label>
                                                        <select
                                                            name="mobile_no"
                                                            id="mobile_no"
                                                            className="form-control"
                                                            onChange={(e) => setUserMobile(e.target.value)}
                                                        >
                                                            {userMobiles &&
                                                                userMobiles.map((itemx: any, index: number) => (
                                                                    <option key={index} value={itemx}>
                                                                        {itemx}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                        <label>{t('Recurring plan')}</label>
                                                        <select
                                                            name="recurringPlan"
                                                            id="recurringPlan"
                                                            className="form-control"
                                                            onChange={(e) => setRecurringPlan(e.target.value)}
                                                        >
                                                            <option value="daily">{t('Daily')}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-clr total-price">
                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 left">
                                        <div className="row">
                                            <p>{t('Total ticket price')}</p>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 right">
                                        <div className="row">
                                            <p>
                                                {tab == 1 && (
                                                    <>
                                                        <NumberFormat
                                                            value={
                                                                paymentOption == 1
                                                                    ? (lottery.ticket_price + CONVENIENT_FEE) *
                                                                    ticketCount
                                                                    : lottery.ticket_price * ticketCount
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix="LKR "
                                                            suffix="/="
                                                        />

                                                        {paymentOption == 1 && ticketCount > 0
                                                            ? ' + tax'
                                                            : ''}
                                                    </>
                                                )}

                                                {tab != 1 && (
                                                    <>
                                                        <NumberFormat
                                                            value={
                                                                paymentOption == 1
                                                                    ? (lottery.ticket_price + CONVENIENT_FEE) *
                                                                    selectedTickets.length
                                                                    : lottery.ticket_price * selectedTickets.length
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            suffix="/="
                                                            prefix="LKR "
                                                        />

                                                        {paymentOption == 1 && selectedTickets.length > 0
                                                            ? ' + tax'
                                                            : ''}
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {user && (user.nicno == null || user.nicno.length == 0) && (
                                    <div className="row detail-row">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <label>{t('NIC Number')}</label>
                                            <input
                                                type="text"
                                                name="nicno"
                                                id="nicno"
                                                autoComplete="off"
                                                className="form-control"
                                                pattern="^([0-9]{9,12})(V|v|x|X)*$"
                                                onChange={(e) => setNicNo(e.target.value)}
                                                //onBlur={(e) => handleUpdateNicNo(e)}
                                                defaultValue={nicno}
                                                ref={nicRef}
                                            />
                                            <p data-aos="fade-down">
                                                <span className="has-error">{nicErrMsg}</span>
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="row payment-method-wrapper"></div>
                                <div className="row payment-method-wrapper">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <h4>Payment Method</h4>
                                    </div>
                                    {paymentReference ?
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="radio"
                                                    checked={paymentOption == 3}
                                                    value="3"
                                                    onClick={(e) =>
                                                        handlePaymenOptionOnChange(e.target.value)
                                                    }
                                                />

                                                <span>{t('iPay Payment')}{' '}</span>
                                            </label>
                                        </div>
                                        :
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="radio"
                                                    checked={paymentOption == 2}
                                                    value="2"
                                                    onClick={(e) =>
                                                        handlePaymenOptionOnChange(e.target.value)
                                                    }
                                                />

                                                <span>
                      {t('Account Blance Payment', {
                          account_balance: accountBalance.toFixed(2),
                      })}{' '}
                    </span>
                                            </label>

                                            {tab == 1 &&
                                                !topUpOpen &&
                                                accountBalance < lottery.ticket_price * ticketCount && (
                                                    <a
                                                        className="topup-click"
                                                        onClick={(e) => setTopUpOpen(!topUpOpen)}
                                                    >
                                                        {t('Topup now')}
                                                    </a>
                                                )}

                                            {tab != 1 &&
                                                !topUpOpen &&
                                                accountBalance <
                                                lottery.ticket_price * selectedTickets.length && (
                                                    <a
                                                        className="topup-click"
                                                        onClick={(e) => setTopUpOpen(!topUpOpen)}
                                                    >
                                                        {t('Topup now')}
                                                    </a>
                                                )}

                                            <img
                                                src={require('../../assets/images/popup/4.png')}
                                                className="img-responsive"
                                                alt="Image"
                                            />
                                        </div>
                                    }

                                    {/*
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  {paymentDisable == true ? (
                    <>
                      <label>
                        <input
                          type="radio"
                          name="radio"
                          checked={paymentOption == 1}
                          value="1"
                          onClick={(e) =>
                            handlePaymenOptionOnChange(e.target.value)
                          }
                        />

                        <span>{t('Add to my mobile bill')}</span>
                      </label>

                      <p>{userMobile}</p>

                      {paymentOption == 1 && (
                        <p className="has-warnning">
                          {t('Mobile Tax Statment', {
                            convenience_fee: CONVENIENT_FEE,
                          })}
                        </p>
                      )}
                    </>
                  ) : ''}
                </div> */}
                                </div>

                                {(topUpOpen && !paymentReference) && (
                                    <>
                                        <div className="row topup-wrapper row-clr" id="topup-wrapper">
                                            <TopupForm
                                                open={open1}
                                                total={
                                                    tab == 1
                                                        ? lottery.ticket_price * ticketCount - accountBalance
                                                        : tab == 2 && selectedTickets.length > 0
                                                            ? lottery.ticket_price * selectedTickets.length -
                                                            accountBalance
                                                            : MINIMUM_TOPUP_AMOUNT
                                                }
                                                handleClose={handleTopupClose}
                                                handleBuyTickets={handleSubscription}
                                                tickets={{}}
                                                subscriptionRequest={subscriptionRequest}
                                                handlErrors={handleTopupFormErrors}
                                                lotteryName={lottery.name}
                                                readOnly={true}
                                            />
                                        </div>
                                    </>

                                )}

                                <div className="buy-ticket-wrapper">
                                    {errMsg && (
                                        <p ref={errRef} aria-live="assertive">
                    <span className={errMsg ? 'has-error' : 'offscreen'}>
                      {errMsg}
                    </span>
                                        </p>
                                    )}
                                    <div className="row row-clr button-wrapper">
                                        <BlockUi tag="div" blocking={blocking}>
                                            {paymentOption == 3 && !showConfirm && (
                                                <button
                                                    type="button"
                                                    className="button-type-7"
                                                    onClick={() => setShowConfirm(true)}
                                                >
                                                    {' '}
                                                    {t('Schedule and Buy Ticket(s)')}
                                                </button>
                                            )}
                                            {paymentOption == 2 && !showConfirm && (
                                                <button
                                                    type="button"
                                                    className="button-type-7"
                                                    onClick={() => setShowConfirm(true)}
                                                >
                                                    {' '}
                                                    {t('Schedule and Buy Ticket(s)')}
                                                </button>
                                            )}
                                            {paymentOption == 1 && !showConfirm && (
                                                <button
                                                    type="button"
                                                    className="button-type-7"
                                                    onClick={() => setShowConfirm(true)}
                                                >
                                                    {' '}
                                                    {t('Schedule and Buy Ticket(s)')}
                                                </button>
                                            )}
                                        </BlockUi>
                                    </div>
                                    <p>
                                        {' '}
                                        <a target="_blank" href="/terms">
                                            {t('Terms and conditions apply')}
                                        </a>
                                    </p>
                                </div>
                                {showConfirm && (
                                    <div
                                        className="row confirm-wrapper row-clr"
                                        id="confirm-wrapper"
                                        data-aos={`fade-${showConfirm ? 'down' : 'up'}`}
                                    >
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="row bottom-wrapper">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p>
                                                        {t('Do You wish to confirm your Subscription plan?')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row bottom-wrapper">
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <BlockUi tag="div" blocking={blocking}>
                                                        <button type="submit" className="button-type-8">
                                                            {t('Yes')}
                                                        </button>
                                                    </BlockUi>
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                                    <button
                                                        type="button"
                                                        className="button-type-8"
                                                        onClick={() => setShowConfirm(false)}
                                                    >
                                                        {t('No')}
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    )}

                    {token && perchaseSuccess && (
                        <div className="row popup-section">
                            <div className="row heading">
                                <div className="col-xs-10 col-sm-10 col-md-11 col-lg-11">
                                    &nbsp;
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-1 col-lg-1">
                                    <img
                                        data-dismiss="modal"
                                        src={require('../../assets/images/buy-form/back-button.png')}
                                        onClick={handleClose}
                                        className="img-responsive close-button"
                                        alt="Image"
                                    />
                                </div>
                            </div>
                            <div className="confirmation-wrapper">
                                <img
                                    src={require('../../assets/images/confirmation-1.png')}
                                    className="img-responsive"
                                    alt="Image"
                                />
                                <h4>
                                    {t('Subscribe')} {subscription?.ticket_quantity}
                                    {subscription?.lottery_name}
                                    <br/>
                                    Total Spent
                                    <NumberFormat
                                        prefix="LKR "
                                        value={subscription?.ticket_quantity * lottery.ticket_price}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        suffix="/="
                                    />{' '}
                                    {subscription?.package_name}
                                </h4>
                                <div className="row confirmation-row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <p>{subscription?.mobile_no}</p>
                                        <h5>{t('Mobile Number')}</h5>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <p>{subscription?.start_time}</p>
                                        <h5>{t('Date')}</h5>
                                    </div>
                                </div>
                                {transaction && transaction?.id && (
                                    <>
                                        <div className="row confirmation-row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <p> {transaction?.id}</p>
                                                <h5>{t('Invoice Number')}</h5>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                <p>{transaction?.txn_id}</p>
                                                <h5>{t('Bank Referance')}</h5>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="row confirmation-button-row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                                        <Link className="button-type-7" to="/my-subscriptions">
                                            {t('Go to My Subscription')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>}
        </>
    )
}

export default withTranslation()(ScheduleForm)
