import React from 'react';

const countTime = {
    color: "red",
    fontFamily: "Arial",
    fontSize: 10
};

const cartCountTime = {
    color: "red",
    marginBottom: 0
};

const formatTime = (value) => {
    const formattedValue = value < 10 ? `0${value}` : value;
    return formattedValue;
};

const DateTimeDisplay = ({value, type, isDanger, isCart}) => {

    const formattedValue = formatTime(value);

    return (
        <div className={isDanger ? 'countdown danger' : 'countdown'}>
            <p style={isCart ? cartCountTime : countTime}>{formattedValue}</p>
            <span>{type}</span>
        </div>
    );
};

export default DateTimeDisplay;