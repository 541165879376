import React, { useEffect } from 'react';

const IsPurchasedConversionTracking = () => {
    useEffect(() => {
        // Place the Google Ads conversion tracking snippet here
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11265615508'; // Replace with your Google Ads ID
        document.head.appendChild(script);


        script.onload = () => {
            window.dataLayer = window.dataLayer || [];


            function gtag(p0: string, p1: string, p2: { send_to: string; transaction_id: string; }) {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('event', 'conversion', {
                'send_to': 'AW-11265615508/Ue_jCKmE2qsZEJTN7vsp',
                'transaction_id': ''
            });
        };

        return () => {
            // Clean up the script when component unmount
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div>
            {/* Optional: Add any content here if needed */}
        </div>
    );
};

export default IsPurchasedConversionTracking;