import React, {useEffect, useState} from "react";
import "./style.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

const PopUpComponent = () => {
    const [shouldShowPopUp, setShouldShowPopUp] = useState(false);
    const [imageBanners, setImageBanners] = useState([]);
    const [currentBanner, setCurrentBanner] = useState(null);

    const axiosPrivate = useAxiosPrivate();
    const {auth} = useAuth();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getData = async () => {
            try {
                const response = await axiosPrivate.get("Banner/HomeBanners/popup");
                setImageBanners(response.data.popup_banners);
            } catch (err) {
                console.error(err);
            }
        };

        getData();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, []);

    useEffect(() => {
        if (imageBanners.length > 0) {
            const lastPopUpTime = localStorage.getItem("lastPopUpTime");
            const currentBannerIndex = parseInt(localStorage.getItem("currentBannerIndex"), 10) || 0;
            const now = new Date().getTime();

            const fiveDaysInMillis = 5 * 24 * 60 * 60 * 1000;
            // const fiveDaysInMillis = 60000;
            const oneDayInMillis = 24 * 60 * 60 * 1000;
            // const oneDayInMillis = 10000;

            if (!lastPopUpTime || (now - parseInt(lastPopUpTime)) >= fiveDaysInMillis) {
                setTimeout(() => {
                    setShouldShowPopUp(true);
                    document.body.style.overflow = "hidden";
                }, 5000);
                setCurrentBanner(imageBanners[currentBannerIndex]);
                localStorage.setItem("lastPopUpTime", now.toString());
                localStorage.setItem("currentBannerIndex", (currentBannerIndex + 1) % imageBanners.length);
            } else if ((now - parseInt(lastPopUpTime)) >= oneDayInMillis) {
                setTimeout(() => {
                    setShouldShowPopUp(true);
                    document.body.style.overflow = "hidden";
                }, 5000);
                setCurrentBanner(imageBanners[(currentBannerIndex + 1) % imageBanners.length]);
                localStorage.setItem("lastPopUpTime", now.toString());
                localStorage.setItem("currentBannerIndex", (currentBannerIndex + 1) % imageBanners.length);
            }
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [imageBanners]);

    const handleClosePopUp = () => {
        setShouldShowPopUp(false);
        document.body.style.overflow = "auto";
    };

    return (
        <>
            {shouldShowPopUp && (
                <section>
                    <div className="popup-backdrop" onClick={() => handleClosePopUp()}>
                        <div data-aos="fade-in" className="row popup-banner-wrapper">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 top-wrapper">
                                {/*<h4>2500 users are buying Govisetha </h4>*/}
                                <img id="closeButton" onClick={() => handleClosePopUp()}
                                     src={require("../../assets/images/home/close-icon.png")}
                                     className="img-responsive close-icon"
                                     alt="Image"
                                />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{padding: 0}}>
                                <img
                                    src={currentBanner.web_image_path}
                                    className="img-responsive web-image"
                                    alt="Image"
                                />
                                <img
                                    src={currentBanner.mobile_image_path}
                                    className="img-responsive mobile-image"
                                    alt="Image"
                                />
                            </div>
                            {/*  <div className="col-xs-12 col-sm-4 col-md-9 col-lg-9">*/}
                            {/*    <div className="row">*/}
                            {/*      <p>*/}
                            {/*        Rupees 61,172,142.00 is 1 day a head. Dont<br /> miss your chance*/}
                            {/*      </p>*/}
                            {/*      <a className="button-type-6 aos-init aos-animate" href="">*/}
                            {/*        Buy Govisetha*/}
                            {/*      </a>*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default PopUpComponent;
