import React, {createContext, useContext, useEffect, useState} from 'react';
import moment from "moment-timezone-all";

const CartContext = createContext();

export const CartProvider = ({children}) => {
    const [cart, setCart] = useState([]);
    const [cartEndTime, setCartEndTime] = useState(null);
    const [tabId, setTabId] = useState(null);
    const [uniqueId, setUniqueId] = useState(null);
    const [customerPremiumC, setCustomerPremiumC] = useState(0.0)
    const [ccwpPremiumC, setCcwpPremiumC] = useState(0.0)
    const [ceylincoOnoActiveStatusC, setCeylincoOnoActiveStatusC] = useState()

    useEffect(() => {
        if (!uniqueId) {
            const timestamp = moment.tz('Asia/Colombo').format('YYYYMMDDHHmmss');
            const randomKey = Math.floor(100000 + Math.random() * 900000);
            const newUniqueId = `${timestamp}${randomKey}`;
            setUniqueId(newUniqueId);
        }
    }, [uniqueId]);

    const addToCart = (item) => {
        setCart([...cart, item]);
    };

    useEffect(() => {
        if (cartEndTime) {
            const timer = setTimeout(() => {
                resetCart();
            }, cartEndTime - Date.now());

            return () => clearTimeout(timer);
        }
    }, [cartEndTime]);

    const addToQuickBuy = (lotteryId, drawId, quantity, source) => {
        setCart(prev => {

            if (tabId === null) {
                setTabId(uniqueId)
            }

            if (cart.length === 0) {
                const currentTime = new Date();
                const timeFiveMinutesLater = new Date(currentTime.getTime() + 5 * 60000);
                setCartEndTime(new Date(timeFiveMinutesLater));
            }

            const existingLottery = prev.find(ticket => ticket.lottery_id === lotteryId && ticket.source === 'quick_buy');

            if (existingLottery) {
                return prev.map(ticket =>
                    ticket.lottery_id === lotteryId && ticket.source === 'quick_buy'
                        ? {...ticket, quantity: ticket.quantity + quantity, draw_id: drawId, source}
                        : ticket
                );
            } else {
                console.log('newCart', [...prev, {lottery_id: lotteryId, quantity, draw_id: drawId, source}])
                return [...prev, {lottery_id: lotteryId, quantity, draw_id: drawId, source}];
            }
        });
    };


    const removeFromCart = (lottery_id, source) => {
        setCart(cart.filter(item => !(item.lottery_id === lottery_id && item.source === source)));
    };

    const removeFromCartByTicketId = (ticket_id) => {
        setCart(cart.filter(item => !(item.id === ticket_id)));
    };

    const resetCart = () => {
        setCart([]);
        setCartEndTime(null);
    };


    return (
        <CartContext.Provider value={{
            cart,
            setCart,
            addToCart,
            removeFromCart,
            removeFromCartByTicketId,
            resetCart,
            addToQuickBuy,
            cartEndTime,
            setCartEndTime,
            tabId,
            setTabId,
            uniqueId,
            setCustomerPremiumC,
            setCcwpPremiumC,
            setCeylincoOnoActiveStatusC,
            customerPremiumC,
            ccwpPremiumC,
            ceylincoOnoActiveStatusC
        }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    return useContext(CartContext);
};
