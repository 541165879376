import { withTranslation } from "react-i18next";
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const FAQ = ({ t }: any) => {

   const faqs = [
      {
         'id': 0,
         'question': 'How to buy a lottery on line?',
         'answer': "tion latine aliquip id, mea ad tale illud definitiones. Periculis omittantur necessitatibus eum ad, pro eripuit minimum.",
         'open': false,
      },
      {
         'id': 1,
         'question': 'How to buy a lottery on line',
         'answer': "tion latine aliquip id, mea ad tale illud definitiones. Periculis omittantur necessitatibus eum ad, pro eripuit minimum.",
         'open': false,
      },
      {
         'id': 2,
         'question': 'How to buy a lottery on line',
         'answer': "tion latine aliquip id, mea ad tale illud definitiones. Periculis omittantur necessitatibus eum ad, pro eripuit minimum.",
         'open': false,
      }
   ];
   return (
      <>

         <section className="faq-section">
            <div className="container">
               <div className="row top-wrapper">
                  <div data-aos="fade-up" className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                     <h2>FAQ</h2>
                     <p>
                        Frequently Asked Questions
                     </p>
                  </div>
               </div>

               <div data-aos="fade-up" className="row bottom-row">

                  <Accordion className="Accordions" defaultActiveKey="0">
                     <Accordion className="Accordion_item" >
                        <Accordion.Header className="title_tab" >
                           <h3 className="title"><span className="icon"></span>{t('What is an Advance Payment Account or My Account?')}</h3>
                        </Accordion.Header>
                        <Accordion.Body>
                           <p>
                              <b>{t('This account enables customers to,')}</b>
                              <ul>
                                 <li>{t('Maintain Winning Balances.')}</li>
                                 <li>{t('To Top-Up using a payment gateway (Credit / Debit Card).')} </li>
                                 <li>{t('This method enables ticket purchase at Rs 40.00 ')}</li>
                                 <li>{t('Monitor Monthly Lottery Spend Vs Winnings.')}</li>
                              </ul>
                           </p>
                           <p>
                              <b>{t('Special Note')} :-</b>
                              {t(' Direct deposits to a bank account bearing name Cinnamon Chip Wealth Promoters (Pvt) Ltd is permitted but for a minimum deposit value of Rs 500.00 (Deposit slip must be sent via WhatsApp to 077 61 61 811)')}.
                           </p>
                        </Accordion.Body>
                     </Accordion>
                  </Accordion>
                  <Accordion className="Accordions" defaultActiveKey="0">
                     <Accordion className="Accordion_item" >
                        <Accordion.Header className="title_tab" >
                           <h3 className="title"><span className="icon"></span>{t('What is the Minimum Top-Up required to Purchase tickets at Rs. 40/- ?')}</h3>
                        </Accordion.Header>
                        <Accordion.Body>
                           <p>
                              {t('Minimum Top-Up via the payment gateway is Rs 200.00')}
                           </p>
                           <p>
                              <b>{t('Special Note')} :-</b>
                              {t('Any AP Account funds that have not shown any activity for a period of 3 months will be expired (All funds will be utilised for the purchase of any available Lottery ticket/s).')}<br />
                              {t('Any Refunds from the AP Account. (Permitted) ')}<br />
                              {t('Refunds will be processed within 03 working days')}.
                           </p>
                        </Accordion.Body>
                     </Accordion>
                  </Accordion>
               </div>
               <div className="row button-row-1">
                  <Link data-aos="fade-up" className="button-type-7 " to="/faq">{t("See More")}</Link>
               </div>
            </div>
         </section>


      </>
   );
};

export default withTranslation()(FAQ);