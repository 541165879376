import axios from '../../api/axios'
import useAuth from '../useAuth'

const useRefreshToken = () => {
  const { setAuth } = useAuth()
  // console.log("useRefreshToken")
  const refresh = async () => {
    console.log("refresh")
    const response = await axios.get('refresh', {
      withCredentials: true,
    })
    setAuth((prev) => {
      // console.log("refresh inside Auth")
      return { ...prev, accessToken: response.data.atkn }
    })

    return response.data.accessToken
  }
  return refresh
}

export default useRefreshToken
