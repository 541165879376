import HomeBanner from '../../components/HomeBanner'
import LotteryGrid from '../../components/LotteryGrid'
import HowItWorks from '../../components/HowItWorks'
import LotteryResult from '../../components/LotteryResult'
import WhyBuyingLottery from '../../components/WhyBuyingLottery'
import OurNextMillionaire from '../../components/OurNextMillionaire'

import CollectSpecial from '../../components/CollectSpecial'
// import ReasonPosts from '../../components/ReasonPosts'
import FAQ from '../../components/FAQ'
import HappyWinners from '../../components/HappyWinners'
import ReactGA from 'react-ga'
import {useEffect, useState} from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import LazyLoad from 'react-lazy-load';
import PopupBanner from '../../components/PopupBanner'
import Snowfall from 'react-snowfall'
import PopUpComponent from "../../components/PopUpComponent";
import QuickBuyForm from "../../components/QuickBuyForm";
import ScheduleForm from "../../components/ScheduleForm";


const Home = (props: any) => {
    const {
        setQuickOpen,
        quickOpen,
        setScheduleOpen,
        scheduleOpen,
        setSelectedLottery,
        selectedLottery,
        quickBuyLoginOpen,
        scheduleBuyLoginOpen,
        setQuickBuyLoginOpen,
        setScheduleBuyLoginOpen,
        isLoggedOut,
        loginOpen
    } = props
    const WHATSAPP_PHONE_NUMBER = process.env.WHATSAPP_PHONE_NUMBER
    const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_CODE
    ReactGA.initialize(TRACKING_ID)
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        if (quickOpen || scheduleOpen) {
            scrollToTop();
        }
    }, [quickOpen, scheduleOpen]);

    useEffect(() => {
        if (isLoggedOut === true) {
            setQuickOpen(false);
            setScheduleOpen(false);
            setQuickBuyLoginOpen(false);
            setScheduleBuyLoginOpen(false);
        }
    }, [isLoggedOut]);


    const handleQuickBuyModelClose = () => {
        setQuickOpen(false)
        setQuickBuyLoginOpen(false)
    }

    const handleScheduleModelClose = () => {
        setScheduleOpen(false)
        setScheduleBuyLoginOpen(false)
    }

    return (
        <>
            {/* <Snowfall
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
          }}
        /> */}

            {/* <DepthOfFieldSnowfall count={100}
                      style={{
                        // Position must be relative or absolute,
                        // because snowflakes are positioned absolutely.
                        position: 'fixed',
                        color:"white",
    width: '100vw',
    height: '100vh',
                      }}/> */}

            {!quickOpen && !scheduleOpen && !quickBuyLoginOpen && !scheduleBuyLoginOpen && !loginOpen &&
                <>
                    <PopUpComponent/>

                    <HomeBanner
                        title="Home Page Banner Line1"
                        title2="Home Page Banner Line2"
                        title3="Home Page Banner Line3"
                        body="Home Page Banner Body"
                        reg_button="Home Page Banner Register Now Button"

                    />
                    <br/>
                    {/* <PopupBanner /> */}

                    {/* < CollectSpecial/> */}
                    <LotteryGrid
                        title="Lotteries Header"
                        sub_title="Lotteries Sub Header"
                        next_price="Next Super Price"
                        sold_out="Sold Out"
                        subscribe="Subscribe"
                        schedule="Schedule"
                        subscribe_and_buy="Subscribe & Buy"
                        buy_now="Buy Now"
                        draw_no="Draw No"
                        draw_date="Draw Date"
                        draw_close="Draw Close"
                        setQuickOpen={setQuickOpen}
                        setScheduleOpen={setScheduleOpen}
                        setSelectedLottery={setSelectedLottery}
                        setQuickBuyLoginOpen={setQuickBuyLoginOpen}
                        setScheduleBuyLoginOpen={setScheduleBuyLoginOpen}
                    />
                    <br/>
                    {/* < CollectSpecial/> */}
                    <br/>

                    {/* <HomeBanner
        title="Home Page Banner Line1"
        title2="Home Page Banner Line2"
        title3="Home Page Banner Line3"
        body="Home Page Banner Body"
        reg_button="Home Page Banner Register Now Button"

      /> */}

                    {/* <LotteryGrid
        title="Lotteries Header"
        sub_title="Lotteries Sub Header"
        next_price="Next Super Price"
        sold_out="Sold Out"
        subscribe="Subscribe"
        schedule="Schedule"
        schedule_and_buy="Schedule And Buy"
        quick_buy="Quick Buy"
        draw_no="Draw No"
        draw_date="Draw Date"
        draw_close="Draw Close"
      /> */}
                </>
            }

            <>
                <QuickBuyForm
                    handleClose={handleQuickBuyModelClose}
                    quickOpen={quickOpen}
                    setQuickOpen={setQuickOpen}
                    quickBuyLoginOpen={quickBuyLoginOpen}
                    lottery={selectedLottery}
                    isLoggedOut={isLoggedOut}
                    title={'Login'}
                />

                <ScheduleForm
                    handleClose={handleScheduleModelClose}
                    scheduleOpen={scheduleOpen}
                    setScheduleOpen={setScheduleOpen}
                    scheduleBuyLoginOpen={scheduleBuyLoginOpen}
                    lottery={selectedLottery}
                    isLoggedOut={isLoggedOut}
                    title={'Login'}
                />
            </>

            {!quickOpen && !scheduleOpen && !quickBuyLoginOpen && !scheduleBuyLoginOpen && !loginOpen &&
                <>
                    <HowItWorks/>
                    <LotteryResult page="home"/>
                    <WhyBuyingLottery/>
                    <OurNextMillionaire/>


                    {/* <HappyWinners /> */}
                    <FAQ/>
                    {/*<MessengerCustomerChat*/}
                    {/*    pageId="106803611863323"*/}
                    {/*    appId="3229410003991004"*/}
                    {/*/>*/}
                    <WhatsAppWidget
                        phoneNo={WHATSAPP_PHONE_NUMBER}
                        position="right"
                        widgetWidth="300px"
                        widgetWidthMobile="260px"
                        autoOpen={false}
                        // autoOpenTimer={5000}
                        messageBox={true}
                        // messageBoxTxt="Hi Team, is there any related service available ?"
                        iconSize="44"
                        iconColor="white"
                        iconBgColor="green"//rgb(7, 94, 84)
                        // headerIcon="https://proficientdesigners.in/wp-content/themes/pd/img/logo-new.png"
                        headerIconColor="blue"
                        // headerTxtColor="black"
                        // headerBgColor="tomato"
                        // headerTitle="John Doe"
                        headerCaption="Online"
                        // bodyBgColor="#bbb"
                        chatPersonName="811NLB"
                        // chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
                        // footerBgColor="#999"
                        btnBgColor="Green"
                        btnTxtColor="white"
                        btnTxt="Start Chat"
                        // sendButton="Start"
                    />
                </>
            }
        </>
    )
}

export default Home
