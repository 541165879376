import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const OurNextMillionaire = ({ t }: any) => {
  return (
    <>
      <section className="our-next-millionaire">
        <div data-aos="fade-up" className="container">
          <h3>Be the Change, Be a Millionaire!</h3>
          <p>
            Don't hesitate! Click Today for a brighter tomorrow! <br />
            CCWP will be with you every step of the way!
          </p>
      
          <Link to="/lotteries" data-aos="fade-down" className="button-type-4">
            {t('Get Lotteries')}
          </Link>
        </div>
      </section>
    </>
  )
}

export default withTranslation()(OurNextMillionaire)
